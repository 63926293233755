import { logger } from '@/logging/FrontendLogger'
import { setRecoilExternalState } from '@/components/RecoilExternalStatePortal'
import { isLoggedInAtom } from '@/atoms/isLoggedIn'
import { logout } from '@/api/cb/contentFrontendAuthentication'
import { wbLogout } from '@/api/wb/authentication'

export const frontendLogout = async (): Promise<void> => {
    logger.info(`Frontend logging out`)
    await Promise.all([logout(), wbLogout()])
    setRecoilExternalState(isLoggedInAtom, false)
    // resets recoil by doing a hard refresh
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location = window.location.origin
}
