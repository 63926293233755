import {
    WBSearchResultsOutline,
    WBSearchResultsNotecard,
    WBSearchResultsPracticeProblem,
    CFSearchResultsSourceMaterial,
} from 'common/src/elasticSearch/types'
import { ContentPath } from 'common/src/ContentPath'
import assertNever from 'assert-never/index'

// super jank, but essentially passes the information needed from the search result into
// an id that can later be used to route to the correct page
export const buildKeyFromSearchResult = (
    searchResult:
        | WBSearchResultsOutline
        | WBSearchResultsNotecard
        | WBSearchResultsPracticeProblem
        | CFSearchResultsSourceMaterial
): string => {
    return JSON.stringify(buildSearchKeyFromResult(searchResult))
}

const buildSearchKeyFromResult = (
    searchResult:
        | WBSearchResultsOutline
        | WBSearchResultsNotecard
        | WBSearchResultsPracticeProblem
        | CFSearchResultsSourceMaterial
): SearchKey => {
    switch (searchResult.type) {
        case 'sourceMaterial':
            return {
                type: 'sourceMaterial',
                id: searchResult.id,
                contentPath: searchResult.contentPath,
            }
        case 'outline':
            return {
                type: 'outline',
                contentPath: searchResult.contentPath,
            }
        case 'notecard':
            return {
                type: 'notecard',
                id: searchResult.id,
                contentPath: searchResult.contentPath,
            }
        case 'practiceProblem':
            return {
                type: 'practiceProblem',
                id: searchResult.id,
                contentPath: searchResult.contentPath,
            }
        default:
            assertNever(searchResult)
    }
}

export type SearchKey =
    | NotecardSearchKey
    | PracticeProblemSearchKey
    | OutlineSearchKey
    | SourceMaterialSearchKey

interface NotecardSearchKey {
    type: 'notecard'
    id: string
    contentPath: ContentPath
}

interface PracticeProblemSearchKey {
    type: 'practiceProblem'
    id: string
    contentPath: ContentPath
}

interface OutlineSearchKey {
    type: 'outline'
    contentPath: ContentPath
}

interface SourceMaterialSearchKey {
    type: 'sourceMaterial'
    id: string
    contentPath: ContentPath
}
