import {
    RecoilState,
    useRecoilValue,
    useRecoilState,
    useSetRecoilState,
} from 'recoil'
import { useInterval } from '@/hooks/useInterval'
import { timeSinceInUnits } from '@/utils/dateTime'
import { isLoggedInAtom } from '@/atoms/isLoggedIn'
import { useRef, useMemo } from 'react'
import { useLogger } from '@/hooks/useLogger'

const checkIntervalInSec = 60
// Refreshes data if it hasn't been updated in a specified length of time
// Be careful with what depends on this data, it will trigger refreshes of that data as well
export const useRefreshDataByTimestamp = <T>(
    atom: RecoilState<T>,
    timestampAtom: RecoilState<Date>,
    fetcher: () => Promise<T>,
    description: string,
    maxAgeInMinutes: number,
    verbose = true,
    dependOnLogin = false
): void => {
    const logger = useLogger(
        `${description} (${useRefreshDataByTimestamp.name})`
    )
    const setData = useSetRecoilState<T>(atom)
    const [timestamp, setTimestamp] = useRecoilState<Date>(timestampAtom)

    const isLoggedIn = useRecoilValue(isLoggedInAtom)

    const shouldRunInterval = useMemo(() => {
        if (dependOnLogin && !isLoggedIn) return false
        return true
    }, [dependOnLogin, isLoggedIn])
    const isRetrieving = useRef<boolean>(false)

    useInterval(
        () => {
            if (isRetrieving.current) return
            if (timeSinceInUnits(timestamp, 'minutes') >= maxAgeInMinutes) {
                isRetrieving.current = true
                if (verbose) logger.info(`Refreshing data`)
                fetcher()
                    .then((newData) => {
                        setData(newData)
                        setTimestamp(new Date())
                        if (verbose) logger.info(`Successfully refreshed data`)
                        isRetrieving.current = false
                    })
                    .catch((error) => {
                        logger.error(`Error fetching data: ${error}`)
                        throw error
                    })
            }
        },
        shouldRunInterval ? checkIntervalInSec * 1_000 : null
    )
}
