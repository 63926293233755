import React, { Component, ErrorInfo, ReactNode } from 'react'
import {
    ErrorBoundaryProps,
    ErrorBoundaryState,
} from '@/components/ErrorBoundary/ErrorBoundary.types'
import { logger } from '@/logging/FrontendLogger'
import { SomethingWentWrong } from '@/components/somethingWentWrong/SomethingWentWrong'

export class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })

        // You can also log error messages to an error reporting service here
        logger.reactError(error, errorInfo)
    }

    render(): ReactNode {
        if (this.state.errorInfo) {
            if (process.env.NEXT_PUBLIC_JEP_MODE === 'dev') {
                // Error path
                return (
                    <div>
                        <h2>Something went wrong.</h2>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </div>
                )
            } else {
                return <SomethingWentWrong />
            }
        }
        // Normally, just render children
        return this.props.children
    }
}
