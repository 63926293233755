import { message } from 'antd'
import { MessageKeys } from '@/utils/messageKeys'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { ReactNode } from 'react'

export const syncSuccessMessage = (content: ReactNode, duration = 3): void => {
    message.success({
        content,
        duration,
        key: MessageKeys.SYNC_SUCCESS,
        className: reusableCssClass.clickMe,
        onClick: () => message.destroy(MessageKeys.SYNC_SUCCESS),
    })
}

export const syncErrorMessage = (content: ReactNode, duration = 3): void => {
    message.error({
        content,
        duration,
        key: MessageKeys.SYNC_ERROR,
        className: reusableCssClass.clickMe,
        onClick: () => message.destroy(MessageKeys.SYNC_ERROR),
    })
}
