import React, { ReactElement, useMemo, useCallback } from 'react'
import { Menu, Tooltip, Empty } from 'antd'
import _ from 'lodash'
import {
    SearchResultsProps,
    GenericWBSearchResult,
} from '@/components/search/SearchBox/searchResults/SearchResults.types'
import {
    buildKeyFromSearchResult,
    SearchKey,
} from '@/components/search/SearchBox/searchResults/utils'
import { NewlineText } from '@/components/utils/newlineText'
import { MenuInfo } from 'rc-menu/lib/interface'
import { SearchContext } from 'common/src/elasticSearch/types'
import assertNever from 'assert-never/index'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { isSearchBoxOpenAtom } from '@/atoms/isSearchBoxOpen'
import { viewingStateAtom, ViewingStateType } from '@/atoms/viewingState'
import { SEARCH_CONTEXTS } from '@/components/search/SearchBox/SearchBox.types'

export const SearchResults: React.FC<SearchResultsProps> = (
    props
): ReactElement => {
    const isSearchBoxOpen = useRecoilValue(isSearchBoxOpenAtom)
    const areSearchResultsPresentButEmpty = useMemo((): boolean => {
        if (!props.searchResults) return false
        for (const groupResults of Object.values(props.searchResults)) {
            if (groupResults.length) return false
        }
        return true
    }, [props.searchResults])
    const searchResults = useMemo((): ReactElement[] | null => {
        if (!(props.searchResults && isSearchBoxOpen)) return null
        if (areSearchResultsPresentButEmpty) {
            return [<Empty key={'no-data'} />]
        }
        const output: ReactElement[] = []
        for (const [groupContext, groupResults] of Object.entries(
            props.searchResults
        )) {
            if (!groupResults.length) continue
            output.push(
                <Menu.SubMenu
                    key={groupContext}
                    title={_.startCase(groupContext)}
                >
                    {(groupResults as GenericWBSearchResult[]).map(
                        (singleResult, index) => (
                            <Menu.Item
                                key={buildKeyFromSearchResult(singleResult)}
                                style={{
                                    paddingLeft: '2rem',
                                }}
                            >
                                <Tooltip
                                    placement={'topLeft'}
                                    title={
                                        <NewlineText
                                            text={singleResult.hoverText}
                                        />
                                    }
                                >
                                    <div
                                        id={`cypress-search-result-${groupContext}-context-${index}`}
                                    >
                                        {singleResult.displayText}
                                    </div>
                                </Tooltip>
                            </Menu.Item>
                        )
                    )}
                </Menu.SubMenu>
            )
        }
        return output
    }, [areSearchResultsPresentButEmpty, isSearchBoxOpen, props.searchResults])
    const setViewingState = useSetRecoilState(viewingStateAtom)

    const handleClick = useCallback(
        (info: MenuInfo): void => {
            const searchContext = info.keyPath[1] as SearchContext
            const stringifiedSearchKey = info.keyPath[0] as string

            if (searchContext === 'other') {
                // TODO: handle other
                return
            }

            const searchKey: SearchKey = JSON.parse(stringifiedSearchKey)
            switch (searchKey.type) {
                case 'outline': {
                    setViewingState({
                        contentPath: searchKey.contentPath,
                        viewingStateType: ViewingStateType.outline,
                        id: null,
                    })
                    break
                }
                case 'notecard': {
                    setViewingState({
                        contentPath: searchKey.contentPath,
                        viewingStateType: ViewingStateType.notecards,
                        id: searchKey.id,
                    })
                    break
                }
                case 'practiceProblem': {
                    setViewingState({
                        contentPath: searchKey.contentPath,
                        viewingStateType: ViewingStateType.practiceProblems,
                        id: searchKey.id,
                    })
                    break
                }
                case 'sourceMaterial': {
                    // first 3 are content path, last 1 is id
                    setViewingState({
                        contentPath: searchKey.contentPath,
                        viewingStateType: ViewingStateType.sourceMaterial,
                        id: searchKey.id,
                    })
                    break
                }
                default:
                    assertNever(searchKey)
            }
            props.closeSearchModal()
        },
        [props, setViewingState]
    )

    return (
        <Menu
            className={'search-results'}
            mode={'inline'}
            defaultOpenKeys={SEARCH_CONTEXTS}
            onClick={handleClick}
            selectable={false}
        >
            {searchResults}
        </Menu>
    )
}
