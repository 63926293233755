import React, { ReactElement, useCallback, useMemo } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
    notificationsAtom,
    isNotificationsModalVisibleAtom,
} from '@/atoms/notifications'
import { useWindowSize } from '@/hooks/useWindowSize'
import { Modal, Empty } from 'antd'
import { NotificationModalProps } from '@/components/NotificationModal/NotificationModal.types'
import { NotificationListItem } from '@/components/NotificationModal/NotificationListItem/NotificationListItem'
import { ContentCreatorCommentNotification } from 'common/src/api/contentFrontendVsContentBackend/types'

export const NotificationModal: React.FC<
    NotificationModalProps
> = (): ReactElement => {
    const notifications =
        useRecoilValue<ContentCreatorCommentNotification[]>(notificationsAtom)
    const [isNotificationModalVisible, setIsNotificationModalVisible] =
        useRecoilState<boolean>(isNotificationsModalVisibleAtom)

    const buildListItemForNotification = useCallback(
        (
            item: ContentCreatorCommentNotification,
            index: number
        ): ReactElement => {
            return (
                <NotificationListItem
                    key={index}
                    notification={item}
                    setIsModalVisible={(isVisible) =>
                        setIsNotificationModalVisible(isVisible)
                    }
                />
            )
        },
        [setIsNotificationModalVisible]
    )

    const windowSize = useWindowSize()
    const maxHeight = useMemo((): string => {
        return `${windowSize.height * 0.75}px`
    }, [windowSize.height])
    return (
        <Modal
            style={{ maxHeight: maxHeight }}
            className={'notification-modal'}
            open={isNotificationModalVisible}
            onCancel={() => {
                setIsNotificationModalVisible(false)
            }}
            footer={null}
        >
            <div>
                <div className={'notification-modal-title'}>Notifications</div>
                {notifications?.length ? (
                    notifications.map((notification, index) =>
                        buildListItemForNotification(notification, index)
                    )
                ) : (
                    <Empty key={'no-data'} />
                )}
            </div>
        </Modal>
    )
}
