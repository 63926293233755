import React, { ReactElement, useMemo } from 'react'
import styles from './LoadingComponent.module.less'
import { LoadingComponentProps } from '@/components/utils/LoadingComponent/LoadingComponent.types'
import { Modal } from 'antd'
import { LoadingCalculatingImage } from '@/components/images/LoadingCalculatingImage'
import { LoadingCalculatingAlteredBackgroundImage } from '@/components/images/LoadingCalculatingAlteredBackgroundImage'

const getLoadingImageComponent = (
    useWhiteBackground: boolean
): ReactElement => {
    return useWhiteBackground ? (
        <LoadingCalculatingImage />
    ) : (
        <LoadingCalculatingAlteredBackgroundImage />
    )
}

export const LoadingComponent: React.FC<LoadingComponentProps> = (
    props
): ReactElement => {
    const component = useMemo(
        (): ReactElement => getLoadingImageComponent(props.useWhiteBackground),
        [props.useWhiteBackground]
    )

    return (
        <div className={styles.loadingContainerContainer}>
            <div className={styles.loadingContainer}>{component}</div>
        </div>
    )
}

export const FullScreenLoadingComponent: React.FC<LoadingComponentProps> = (
    props
): ReactElement => {
    return (
        <Modal
            open={true}
            closable={false}
            footer={null}
            // These are not in the antd documentation, but as you would expect, prevents a weird animation from happening (using this modal in a non-standard way, kind of a hack right now)
            transitionName="none"
            maskTransitionName="none"
        >
            <div className={styles.loadingContainerCoverEntireScreen}>
                <LoadingComponent
                    useWhiteBackground={props.useWhiteBackground}
                />
            </div>
        </Modal>
    )
}
