import { ContentPath } from 'common/src/ContentPath'
import { atom, DefaultValue, selector } from 'recoil'

export enum ViewingStateType {
    summary = 'summary',
    outline = 'outline',
    notecards = 'notecards',
    practiceProblems = 'practiceProblems',
    editCourseMetadata = 'editCourseMetadata',
    formulaSheet = 'formulaSheet',
    uploadPreviousExamAnalysis = 'uploadPreviousExamAnalysis',
    courseStats = 'courseStats',
    notecardCategories = 'notecardCategories',
    notecardsWithoutPracticeProblem = 'notecardsWithoutPracticeProblem',
    practiceProblemCategories = 'practiceProblemCategories',
    sourceMaterialTypes = 'sourceMaterialTypes',
    nodeStats = 'nodeStats',
    copyLeafNode = 'copyLeafNode',
    moveLeafNodeWithinSameExam = 'moveLeafNodeWithinSameExam',
    applicationInstructions = 'applicationInstructions',
    sourceMaterial = 'sourceMaterial',
    videoPreview = 'videoPreview',
}

export interface ViewingState {
    contentPath: ContentPath
    viewingStateType: ViewingStateType | null
    id: string | null
}

export const viewingStateAtom = atom<ViewingState>({
    key: 'viewingStateAtom',
    default: {
        contentPath: [],
        viewingStateType: null,
        id: null,
    },
})

export const viewingStateTypeSelector = selector<ViewingStateType>({
    key: 'viewingStateTypeSelector',
    get: ({ get }) => get(viewingStateAtom).viewingStateType,
    set: ({ set }, viewingStateType) => {
        if (viewingStateType instanceof DefaultValue) return
        set(viewingStateAtom, (viewingStateAtom) => ({
            ...viewingStateAtom,
            viewingStateType,
        }))
    },
})

export const activeNotecardIDSelector = selector<string | null>({
    key: 'activeNotecardIDSelector',
    get: ({ get }) => {
        const viewingState = get(viewingStateAtom)
        if (viewingState.viewingStateType !== ViewingStateType.notecards)
            return null

        return viewingState.id
    },
    set: ({ set, get }, id) => {
        if (
            id instanceof DefaultValue ||
            get(viewingStateAtom).viewingStateType !==
                ViewingStateType.notecards
        )
            return

        set(viewingStateAtom, (viewingState) => ({
            ...viewingState,
            id,
        }))
    },
})

export const activePracticeProblemIDSelector = selector<string | null>({
    key: 'activePracticeProblemIDSelector',
    get: ({ get }) => {
        const viewingState = get(viewingStateAtom)
        if (viewingState.viewingStateType !== ViewingStateType.practiceProblems)
            return null

        return viewingState.id
    },
    set: ({ set, get }, id) => {
        if (
            id instanceof DefaultValue ||
            get(viewingStateAtom).viewingStateType !==
                ViewingStateType.practiceProblems
        )
            return

        set(viewingStateAtom, (viewingState) => ({
            ...viewingState,
            id,
        }))
    },
})

export const activeCourseSourceMaterialIDSelector = selector<string | null>({
    key: 'activeCourseSourceMaterialIDSelector',
    get: ({ get }) => {
        const viewingState = get(viewingStateAtom)
        if (viewingState.viewingStateType !== ViewingStateType.sourceMaterial)
            return null

        return viewingState.id
    },
    set: ({ set, get }, id) => {
        if (
            id instanceof DefaultValue ||
            get(viewingStateAtom).viewingStateType !==
                ViewingStateType.sourceMaterial
        )
            return

        set(viewingStateAtom, (viewingState) => ({
            ...viewingState,
            id,
        }))
    },
})
