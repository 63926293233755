export const reusableCssClass = {
    sliderContainer: 'swipeable-views-slider-container',
    clickableText: 'clickable-text',
    clickableTextUnderlined: 'clickable-text underline',
    clickMe: 'click-me',
    hoverMe: 'hover-me',
    noHighlight: 'no-highlight',
    invisible: 'invisible',
    blurMe: 'blur-me',
    dontTouchMe: 'dont-touch-me',
    secondaryText: 'secondary-text',
    wiggle: 'wiggle',
    bigWiggle: 'big-wiggle',

    // For special rr censoring: https://github.com/rrweb-io/rrweb/issues/1#issuecomment-443512654
    rrIgnore: 'rr-ignore', // An element with the class name .rr-block will not be recorded. Instead, it will replay as a placeholder with the same dimension.
    rrBlock: 'rr-block', // An element with the class name .rr-ignore will not record the input events.

    // responsive text, see responsiveText.less
    responsiveTextLarge: 'responsive-text-large',
    responsiveTextMedium: 'responsive-text-medium',
    responsiveTextSmall: 'responsive-text-small',

    singleColumn: 'single-column',
    multiColumn: 'multi-column',

    fullWidth: 'full-width',
    partialWidth: 'partial-width',
    hasUnsavedChanges: 'has-unsaved-changes', // be careful using this, doesn't always work the way you expect it to (pretty much needs to work with index.less)

    bold: 'bold-me',
    centerChildrenVertically: 'center-children-vertically',

    ellipsis: 'ellipsis-me',
    loggedInMarker: 'logged-in-marker',
    loggedOutMarker: 'logged-out-marker',
}

export const reusableCssVar = {
    primaryColor: '#357BAE',
    successColor: '#52c41a',
    warningColor: '#faad14',
    errorColor: '#f5222d',
    lightGrayColor: '#D3D8DA',
    masteredColor: '#357BAE',
    learningColor: 'rgb(158, 82, 86)',
    unseenColor: '#6e7a82',
    nearlyMasteredColor: '#afaefe',
}
