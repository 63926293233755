import { WB_BASE_PREFIX, APIResponseWrapper } from '@/api/types'
import { ApiMethod } from 'common/src/api/types'
import { callBackend } from '@/api/callBackend'
import { CancelToken } from 'axios'
import {
    AddCommentToThreadRequest,
    GetOutlineAndSummaryCommentThreadsForContentPathRequest,
    MinCommentThreadPayload,
    UpvoteRequest,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/requests'
import {
    AddCommentToThreadResponse,
    GetOutlineAndSummaryCommentThreadsForContentPathResponse,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/responses'
import { CommentMetadata } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'

const PREFIX = WB_BASE_PREFIX + '/commentThread'

export const getCommentsForContentTypeAndContentId = async (
    data: MinCommentThreadPayload,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<CommentMetadata[]> => {
    return await callBackend<MinCommentThreadPayload, CommentMetadata[]>(
        PREFIX + '/getCommentsForContentTypeAndContentId',
        ApiMethod.GET,
        data,
        cancelToken
    )
}

export const addCommentToThread = async (
    payload: AddCommentToThreadRequest
): APIResponseWrapper<AddCommentToThreadResponse> => {
    return await callBackend<
        AddCommentToThreadRequest,
        AddCommentToThreadResponse
    >(PREFIX + '/addCommentToThread', ApiMethod.POST, payload)
}

export const enactUpvoteActionOnComment = async (
    payload: UpvoteRequest
): APIResponseWrapper<void> => {
    return await callBackend<UpvoteRequest, void>(
        PREFIX + '/enactUpvoteActionOnComment',
        ApiMethod.POST,
        payload
    )
}

export const getOutlineAndSummaryCommentThreadsForContentPath = async (
    payload: GetOutlineAndSummaryCommentThreadsForContentPathRequest
): APIResponseWrapper<GetOutlineAndSummaryCommentThreadsForContentPathResponse> => {
    return await callBackend<
        GetOutlineAndSummaryCommentThreadsForContentPathRequest,
        GetOutlineAndSummaryCommentThreadsForContentPathResponse
    >(
        PREFIX + '/getOutlineAndSummaryCommentThreadsForContentPath',
        ApiMethod.GET,
        payload
    )
}
