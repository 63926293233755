import { callBackend } from '@/api/callBackend'
import { ApiMethod, EmptyRequest } from 'common/src/api/types'
import { CancelToken } from 'axios'
import { APIResponseWrapper, CB_BASE_PREFIX } from '@/api/types'
import { ContentAuthResponse } from 'common/src/api/contentFrontendVsContentBackend/responses'
import {
    SetFrontendActiveCourseForContentCreatorRequest,
    SetReceivePopups,
} from 'common/src/api/contentFrontendVsContentBackend/requests'

const PREFIX = CB_BASE_PREFIX + '/contentCreatorAccount'

export const getAuth = async (
    cancelToken: CancelToken | null = null
): APIResponseWrapper<ContentAuthResponse> => {
    return await callBackend<EmptyRequest, ContentAuthResponse>(
        PREFIX + '/getAuth',
        ApiMethod.GET,
        null,
        cancelToken
    )
}

export const getCourseNamesForContentCreator = async (
    cancelToken: CancelToken | null = null
): APIResponseWrapper<string[]> => {
    return await callBackend<EmptyRequest, string[]>(
        PREFIX + '/getCourseNamesForContentCreator',
        ApiMethod.GET,
        null,
        cancelToken
    )
}

export const getAllCourseNames = async (
    cancelToken: CancelToken | null = null
): APIResponseWrapper<string[]> => {
    return await callBackend<EmptyRequest, string[]>(
        PREFIX + '/getAllCourseNames',
        ApiMethod.GET,
        null,
        cancelToken
    )
}

export const setFrontendActiveCourseForContentCreator = async (
    newActiveCourseName: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<ContentAuthResponse> => {
    return await callBackend<
        SetFrontendActiveCourseForContentCreatorRequest,
        null
    >(
        PREFIX + '/setFrontendActiveCourseForContentCreator',
        ApiMethod.POST,
        { newActiveCourseName },
        cancelToken
    )
}

export const setReceivePopups = async (
    receivePopups: boolean
): Promise<APIResponseWrapper<ContentAuthResponse>> => {
    return await callBackend<SetReceivePopups, null>(
        PREFIX + '/setReceivePopups',
        ApiMethod.POST,
        { receivePopups }
    )
}
