import { CB_BASE_PREFIX, APIResponseWrapper } from '@/api/types'
import { CancelToken } from 'axios'
import { callBackend } from '@/api/callBackend'
import { GenericContentRequest } from 'common/src/api/core/courses/requests'
import { ApiMethod } from 'common/src/api/types'
import { ContentCreatorCommentNotification } from 'common/src/api/contentFrontendVsContentBackend/types'

const PREFIX = CB_BASE_PREFIX + '/notification'

export const getAllNotificationsForCourse = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<ContentCreatorCommentNotification[]> => {
    return await callBackend<
        GenericContentRequest,
        ContentCreatorCommentNotification[]
    >(
        PREFIX + '/getAllNotificationsForCourse',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const markAllUnseenNotificationsAsSeenForCourse = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<void> => {
    return await callBackend<GenericContentRequest, null>(
        PREFIX + '/markAllUnseenNotificationsAsSeenForCourse',
        ApiMethod.POST,
        { courseName },
        cancelToken
    )
}
