import { callBackend } from '@/api/callBackend'
import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import { APIResponseWrapper, CB_BASE_PREFIX } from '@/api/types'
import {
    ContentTreeAndNodeMetadataResponse,
    ContentTreeResponse,
} from 'common/src/api/core/contentTree/responses'
import {
    GenericContentRequest,
    SpecificContentRequest,
} from 'common/src/api/core/courses/requests'
import { CancelToken } from 'axios'
import { ContentPath } from 'common/src/ContentPath'
import {
    ISlimLeafNodeData,
    LeafMeta,
} from 'common/src/api/backendToBackend/content/types'
import { ContentNodeRequestQuery } from 'common/src/api/backendToBackend/content/requests'
import {
    IGenericPracticeProblem,
    IProblemMetadata,
} from 'common/src/practiceProblems/types'
import { NodeMetadataResponse } from 'common/src/outlines/responses'
import { CourseData } from 'common/src/api/contentFrontendVsContentBackend/types'
import {
    AddSourceMaterialTypeRequest,
    AddNotecardCategoryRequest,
    AddPracticeProblemCategoryRequest,
    ContentNodeMoveRequest,
    ContentReorderRequest,
    CopyContentLeafNodeRequest,
    CopyIndividualContentRequest,
    CreateLearningObjectiveRequest,
    DeleteLearningObjectiveRequest,
    DeleteNotecardAtContentPathRequest,
    DeleteNotecardCategoryRequest,
    DeletePracticeProblemAtContentPathRequest,
    DeletePracticeProblemCategoryRequest,
    MoveIndividualContentRequest,
    OverwriteContentAtContentPathRequest,
    OverwriteDescriptionAtContentPathRequest,
    OverwriteNotecardAtContentPathRequest,
    OverwriteOutlineAtContentPathRequest,
    OverwritePracticeProblemAtContentPathRequest,
    OverwriteSummaryAtContentPathRequest,
    OverwriteTitleAtContentPathRequest,
    SetContentTierTitlesRequest,
    SetNextSittingDateForCourseRequest,
    SetReleaseInfoForCourseRequest,
    UpdateLearningObjectiveRequest,
    UpdateRecommendedStudyHoursForCourseRequest,
    DeleteSourceMaterialTypeRequest,
    MoveContentLeafNodeWithinSameCourseRequest,
    OverwriteNodeReleaseStatusAtContentPathRequest,
    OverwriteNodeVideoLinkAtContentPathRequest,
} from 'common/src/api/contentFrontendVsContentBackend/requests'
import {
    ContentStats,
    CourseInfo,
    NodeReleaseStatus,
} from 'common/src/api/core/courses/types'
import {
    CopyContentLeafNodeResponse,
    CopyIndividualContentResponse,
    CreateLearningObjectiveResponse,
    GetNotecardCategoriesResponse,
    GetNotecardCategoryStatsResponse,
    GetPracticeProblemCategoriesResponse,
    GetPracticeProblemCategoryStatsResponse,
    GetSourceMaterialTypesResponse,
    GetSourceMaterialTypeStatsResponse,
    MoveIndividualContentResponse,
    GetNotecardsWithoutPracticeProblemResponse,
} from 'common/src/api/contentFrontendVsContentBackend/responses'
import { INotecard, NotecardMetadata } from 'common/src/notecards/types'
import { ContentTierTitles } from '@/atoms/contentTierTitles'
import { FeatureFlags, SetFeatureFlagsRequest } from 'common/src/featureFlags'
import {
    DeleteSourceMaterialRequest,
    GetSourceMaterialRequest,
    SourceMaterialData,
    SaveSourceMaterialRequest,
} from 'common/src/api/contentFrontendVsContentBackend/sourceMaterial'
import {
    FormulaSheetEntry,
    GetFormulaSheetEntriesResponse,
    SaveFormulaSheetRequest,
} from 'common/src/formulaSheet/types'

const PREFIX = CB_BASE_PREFIX + '/contentCreatorContent'

export const getContentTree = async (
    courseName: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<ContentTreeResponse> => {
    return await callBackend<GenericContentRequest, ContentTreeResponse>(
        PREFIX + '/getContentTree',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const getAllNodeMetadata = async (
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<NodeMetadataResponse> => {
    return await callBackend<GenericContentRequest, NodeMetadataResponse>(
        PREFIX + '/getAllNodeMetadata',
        ApiMethod.GET,
        {
            courseName,
        },
        cancelToken
    )
}

export const getRootMeta = async (
    courseName: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<CourseData> => {
    return await callBackend<GenericContentRequest, CourseData>(
        PREFIX + '/getRootMeta',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const getLeafMeta = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<LeafMeta> => {
    return await callBackend<ContentNodeRequestQuery, LeafMeta>(
        PREFIX + '/getLeafMeta',
        ApiMethod.GET,
        { courseName, contentPath },
        cancelToken
    )
}

export const getSlimLeafNode = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<ISlimLeafNodeData> => {
    return await callBackend<ContentNodeRequestQuery, ISlimLeafNodeData>(
        PREFIX + '/getSlimLeafNode',
        ApiMethod.GET,
        { courseName, contentPath },
        cancelToken
    )
}

export const getOutline = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<string> => {
    return await callBackend<ContentNodeRequestQuery, string>(
        PREFIX + '/getOutline',
        ApiMethod.GET,
        { courseName, contentPath },
        cancelToken
    )
}

export const getSummary = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<string> => {
    return await callBackend<ContentNodeRequestQuery, string>(
        PREFIX + '/getSummary',
        ApiMethod.GET,
        { courseName, contentPath },
        cancelToken
    )
}

export const getNotecardMeta = async (
    courseName: string,
    id: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<NotecardMetadata> => {
    return await callBackend<SpecificContentRequest, NotecardMetadata>(
        PREFIX + '/getNotecardMeta',
        ApiMethod.GET,
        { courseName, id },
        cancelToken
    )
}

export const getPracticeProblemMeta = async (
    courseName: string,
    id: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<IProblemMetadata> => {
    return await callBackend<SpecificContentRequest, IProblemMetadata>(
        PREFIX + '/getPracticeProblemMeta',
        ApiMethod.GET,
        { courseName, id },
        cancelToken
    )
}

export const getNotecard = async (
    courseName: string,
    id: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<INotecard> => {
    return await callBackend<SpecificContentRequest, INotecard>(
        PREFIX + '/getNotecard',
        ApiMethod.GET,
        { courseName, id },
        cancelToken
    )
}

export const getPracticeProblem = async (
    courseName: string,
    id: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<IGenericPracticeProblem> => {
    return await callBackend<SpecificContentRequest, IGenericPracticeProblem>(
        PREFIX + '/getPracticeProblem',
        ApiMethod.GET,
        { courseName, id },
        cancelToken
    )
}

export const overwriteSummaryAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    summary: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        OverwriteSummaryAtContentPathRequest,
        EmptyResponse
    >(
        PREFIX + '/overwriteSummaryAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, summary },
        cancelToken
    )
}

export const overwriteOutlineAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    outline: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        OverwriteOutlineAtContentPathRequest,
        EmptyResponse
    >(
        PREFIX + '/overwriteOutlineAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, outline },
        cancelToken
    )
}

export const overwriteTitleAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    title: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<OverwriteTitleAtContentPathRequest, EmptyResponse>(
        PREFIX + '/overwriteTitleAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, title },
        cancelToken
    )
}

export const overwriteDescriptionAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    description: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        OverwriteDescriptionAtContentPathRequest,
        EmptyResponse
    >(
        PREFIX + '/overwriteDescriptionAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, description },
        cancelToken
    )
}

export const overwriteNodeReleaseStatusAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    nodeReleaseStatus: NodeReleaseStatus,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        OverwriteNodeReleaseStatusAtContentPathRequest,
        EmptyResponse
    >(
        PREFIX + '/overwriteNodeReleaseStatusAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, nodeReleaseStatus },
        cancelToken
    )
}

export const overwriteNodeVideoLinkAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    videoLink: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        OverwriteNodeVideoLinkAtContentPathRequest,
        EmptyResponse
    >(
        PREFIX + '/overwriteNodeVideoLinkAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, videoLink },
        cancelToken
    )
}

export const deleteNodeAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<ContentTreeAndNodeMetadataResponse> => {
    return await callBackend<
        OverwriteContentAtContentPathRequest,
        ContentTreeAndNodeMetadataResponse
    >(
        PREFIX + '/deleteNodeAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath },
        cancelToken
    )
}

export const overwriteNotecardAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    notecard: INotecard,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        OverwriteNotecardAtContentPathRequest,
        EmptyResponse
    >(
        PREFIX + '/overwriteNotecardAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, notecard },
        cancelToken
    )
}

export const deleteNotecardAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    notecardID: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<DeleteNotecardAtContentPathRequest, EmptyResponse>(
        PREFIX + '/deleteNotecardAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, notecardID },
        cancelToken
    )
}

export const overwritePracticeProblemAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    practiceProblem: IGenericPracticeProblem,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        OverwritePracticeProblemAtContentPathRequest,
        EmptyResponse
    >(
        PREFIX + '/overwritePracticeProblemAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, practiceProblem },
        cancelToken
    )
}

export const deletePracticeProblemAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    practiceProblemID: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        DeletePracticeProblemAtContentPathRequest,
        EmptyResponse
    >(
        PREFIX + '/deletePracticeProblemAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath, practiceProblemID },
        cancelToken
    )
}

export const reorderNotecards = async (
    courseName: string,
    id: string,
    contentPath: ContentPath,
    startingIndex: number,
    endingIndex: number,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<ContentReorderRequest, EmptyResponse>(
        PREFIX + '/reorderNotecards',
        ApiMethod.POST,
        { courseName, id, contentPath, startingIndex, endingIndex },
        cancelToken
    )
}

export const reorderPracticeProblems = async (
    courseName: string,
    id: string,
    contentPath: ContentPath,
    startingIndex: number,
    endingIndex: number,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<ContentReorderRequest, EmptyResponse>(
        PREFIX + '/reorderPracticeProblems',
        ApiMethod.POST,
        { courseName, id, contentPath, startingIndex, endingIndex },
        cancelToken
    )
}

export const addEmptyNodeAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<ContentTreeAndNodeMetadataResponse> => {
    return await callBackend<
        OverwriteContentAtContentPathRequest,
        ContentTreeAndNodeMetadataResponse
    >(
        PREFIX + '/addEmptyNodeAtContentPath',
        ApiMethod.POST,
        { courseName, contentPath },
        cancelToken
    )
}

export const setNextSittingDateForCourse = async (
    courseName: string,
    nextSittingDate: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SetNextSittingDateForCourseRequest, EmptyResponse>(
        PREFIX + '/setNextSittingDateForCourse',
        ApiMethod.POST,
        { courseName, nextSittingDate },
        cancelToken
    )
}

export const createLearningObjective = async (
    courseName: string,
    topicID: string,
    description: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<CreateLearningObjectiveResponse> => {
    return await callBackend<
        CreateLearningObjectiveRequest,
        CreateLearningObjectiveResponse
    >(
        PREFIX + '/createLearningObjective',
        ApiMethod.POST,
        { courseName, topicID, description },
        cancelToken
    )
}

export const updateLearningObjective = async (
    courseName: string,
    id: string,
    description: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<UpdateLearningObjectiveRequest, EmptyResponse>(
        PREFIX + '/updateLearningObjective',
        ApiMethod.POST,
        { courseName, id, description },
        cancelToken
    )
}

export const deleteLearningObjective = async (
    courseName: string,
    learningObjectiveID: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<DeleteLearningObjectiveRequest, EmptyResponse>(
        PREFIX + '/deleteLearningObjective',
        ApiMethod.POST,
        { courseName, learningObjectiveID },
        cancelToken
    )
}

export const setContentTierTitlesForCourse = async (
    courseName: string,
    contentTierTitles: ContentTierTitles,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SetContentTierTitlesRequest, EmptyResponse>(
        PREFIX + '/setContentTierTitlesForCourse',
        ApiMethod.POST,
        { courseName, contentTierTitles },
        cancelToken
    )
}

export const getCourseInfo = async (
    courseName: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<CourseInfo> => {
    return await callBackend<GenericContentRequest, CourseInfo>(
        PREFIX + '/getCourseInfo',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const getNodeStats = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<ContentStats> => {
    return await callBackend<ContentNodeRequestQuery, ContentStats>(
        PREFIX + '/getNodeStats',
        ApiMethod.GET,
        { courseName, contentPath },
        cancelToken
    )
}

export const copyContentLeafNode = async (
    payload: CopyContentLeafNodeRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<CopyContentLeafNodeResponse> => {
    return await callBackend<
        CopyContentLeafNodeRequest,
        CopyContentLeafNodeResponse
    >(
        PREFIX + '/copyContentLeafNodeToDifferentCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const copyIndividualContent = async (
    payload: CopyIndividualContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<CopyIndividualContentResponse> => {
    return await callBackend<
        CopyIndividualContentRequest,
        CopyIndividualContentResponse
    >(PREFIX + '/copyIndividualContent', ApiMethod.POST, payload, cancelToken)
}

export const moveIndividualContent = async (
    payload: MoveIndividualContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<MoveIndividualContentResponse> => {
    return await callBackend<
        MoveIndividualContentRequest,
        MoveIndividualContentResponse
    >(PREFIX + '/moveIndividualContent', ApiMethod.POST, payload, cancelToken)
}

export const moveContentNode = async (
    payload: ContentNodeMoveRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<ContentNodeMoveRequest, EmptyResponse>(
        PREFIX + '/moveContentNode',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const moveContentLeafNodeWithinSameCourse = async (
    payload: MoveContentLeafNodeWithinSameCourseRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<CopyContentLeafNodeResponse> => {
    return await callBackend<
        MoveContentLeafNodeWithinSameCourseRequest,
        CopyContentLeafNodeResponse
    >(
        PREFIX + '/moveContentLeafNodeWithinSameCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const setReleaseInfoForCourse = async (
    payload: SetReleaseInfoForCourseRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SetReleaseInfoForCourseRequest, EmptyResponse>(
        PREFIX + '/setReleaseInfoForCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const getNotecardCategoriesForCourse = async (
    payload: GenericContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetNotecardCategoriesResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetNotecardCategoriesResponse
    >(
        PREFIX + '/getNotecardCategoriesForCourse',
        ApiMethod.GET,
        payload,
        cancelToken
    )
}

export const addNotecardCategoryForCourse = async (
    payload: AddNotecardCategoryRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<AddNotecardCategoryRequest, EmptyResponse>(
        PREFIX + '/addNotecardCategoryForCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const deleteNotecardCategoryForCourse = async (
    payload: DeleteNotecardCategoryRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<DeleteNotecardCategoryRequest, EmptyResponse>(
        PREFIX + '/deleteNotecardCategoryForCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const getNotecardCategoryStatsForCourse = async (
    payload: GenericContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetNotecardCategoryStatsResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetNotecardCategoryStatsResponse
    >(
        PREFIX + '/getNotecardCategoryStatsForCourse',
        ApiMethod.GET,
        payload,
        cancelToken
    )
}

export const getNotecardsWithoutPracticeProblemForCourse = async (
    payload: GenericContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetNotecardsWithoutPracticeProblemResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetNotecardsWithoutPracticeProblemResponse
    >(
        PREFIX + '/getNotecardsWithoutPracticeProblemForCourse',
        ApiMethod.GET,
        payload,
        cancelToken
    )
}

export const getPracticeProblemCategoriesForCourse = async (
    payload: GenericContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetPracticeProblemCategoriesResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetPracticeProblemCategoriesResponse
    >(
        PREFIX + '/getPracticeProblemCategoriesForCourse',
        ApiMethod.GET,
        payload,
        cancelToken
    )
}

export const addPracticeProblemCategoryForCourse = async (
    payload: AddPracticeProblemCategoryRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<AddPracticeProblemCategoryRequest, EmptyResponse>(
        PREFIX + '/addPracticeProblemCategoryForCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const deletePracticeProblemCategoryForCourse = async (
    payload: DeletePracticeProblemCategoryRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        DeletePracticeProblemCategoryRequest,
        EmptyResponse
    >(
        PREFIX + '/deletePracticeProblemCategoryForCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const getPracticeProblemCategoryStatsForCourse = async (
    payload: GenericContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetPracticeProblemCategoryStatsResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetPracticeProblemCategoryStatsResponse
    >(
        PREFIX + '/getPracticeProblemCategoryStatsForCourse',
        ApiMethod.GET,
        payload,
        cancelToken
    )
}

export const getSourceMaterialTypesForCourse = async (
    payload: GenericContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetSourceMaterialTypesResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetSourceMaterialTypesResponse
    >(
        PREFIX + '/getSourceMaterialTypesForCourse',
        ApiMethod.GET,
        payload,
        cancelToken
    )
}

export const addSourceMaterialTypeForCourse = async (
    payload: AddSourceMaterialTypeRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<AddSourceMaterialTypeRequest, EmptyResponse>(
        PREFIX + '/addSourceMaterialTypeForCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const deleteSourceMaterialTypeForCourse = async (
    payload: DeleteSourceMaterialTypeRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<DeleteSourceMaterialTypeRequest, EmptyResponse>(
        PREFIX + '/deleteSourceMaterialTypeForCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const getSourceMaterialTypeStatsForCourse = async (
    payload: GenericContentRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetSourceMaterialTypeStatsResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetSourceMaterialTypeStatsResponse
    >(
        PREFIX + '/getSourceMaterialTypeStatsForCourse',
        ApiMethod.GET,
        payload,
        cancelToken
    )
}

export const setRecommendedStudyHoursForCourse = async (
    payload: UpdateRecommendedStudyHoursForCourseRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<
        UpdateRecommendedStudyHoursForCourseRequest,
        EmptyResponse
    >(
        PREFIX + '/updateRecommendedStudyHoursForCourse',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const getSourceMaterial = async (
    payload: GetSourceMaterialRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<SourceMaterialData> => {
    return await callBackend<GetSourceMaterialRequest, SourceMaterialData>(
        PREFIX + '/getSourceMaterial',
        ApiMethod.GET,
        payload,
        cancelToken
    )
}

export const saveSourceMaterial = async (
    payload: SaveSourceMaterialRequest,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SaveSourceMaterialRequest, EmptyResponse>(
        PREFIX + '/saveSourceMaterial',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}

export const reorderSourceMaterial = async (
    courseName: string,
    id: string,
    contentPath: ContentPath,
    startingIndex: number,
    endingIndex: number,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<ContentReorderRequest, EmptyResponse>(
        PREFIX + '/reorderSourceMaterial',
        ApiMethod.POST,
        { courseName, id, contentPath, startingIndex, endingIndex },
        cancelToken
    )
}

export const deleteSourceMaterial = async (
    courseName: string,
    id: string,
    contentPath: ContentPath,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<DeleteSourceMaterialRequest, EmptyResponse>(
        PREFIX + '/deleteSourceMaterial',
        ApiMethod.POST,
        { courseName, id, contentPath },
        cancelToken
    )
}

export const setFeatureFlags = async (
    courseName: string,
    featureFlags: FeatureFlags,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SetFeatureFlagsRequest, EmptyResponse>(
        PREFIX + '/setFeatureFlags',
        ApiMethod.POST,
        { courseName, featureFlags },
        cancelToken
    )
}

export const getFormulaSheetEntries = async (
    courseName: string,
    cancelToken: CancelToken | null = null
): APIResponseWrapper<GetFormulaSheetEntriesResponse> => {
    return await callBackend<
        GenericContentRequest,
        GetFormulaSheetEntriesResponse
    >(
        PREFIX + '/getFormulaSheetEntries',
        ApiMethod.GET,
        { courseName },
        cancelToken
    )
}

export const overwriteFormulaSheetEntries = async (
    courseName: string,
    formulaSheetEntries: FormulaSheetEntry[],
    cancelToken: CancelToken | null = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SaveFormulaSheetRequest, EmptyResponse>(
        PREFIX + '/overwriteFormulaSheetEntries',
        ApiMethod.POST,
        { courseName, formulaSheetEntries },
        cancelToken
    )
}
