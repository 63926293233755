import React, { ReactElement, Suspense } from 'react'
import type { AppProps } from 'next/app'
import { enableMapSet } from 'immer'
import { RecoilRoot } from 'recoil'
import {
    FullScreenLoadingComponent,
    LoadingComponent,
} from '@/components/utils/LoadingComponent/LoadingComponent'
import { ErrorBoundary } from '@/components/ErrorBoundary/ErrorBoundary'
import dynamic from 'next/dynamic'
import { RecoilExternalStatePortal } from '@/components/RecoilExternalStatePortal'
import { THEME } from '@/globals/theme'
import { ConfigProvider, Layout } from 'antd'
import Head from 'next/head'
import styles from '@/components/layout/Frame/Frame.module.less'
import { TopNavigation } from '@/components/layout/TopNavigation/TopNavigation'
import { LoadingBar } from '@/components/layout/Frame/loadingBar/LoadingBar'
import { Footer } from '@/components/layout/Footer/Footer'
import 'magic-text-box-lexical/src/styles/index.scss'

enableMapSet()

//Global css
require('antd/dist/reset.css')
require('@/styles/index.less')

function ContentFrontend({ Component, pageProps }: AppProps): ReactElement {
    return (
        <>
            <Head>
                <meta charSet="UTF-8" />
                <meta name={'description'} content={'Just Enough Prep'} />
                <meta
                    name={'viewport'}
                    content={'width=device-width, initial-scale=1'}
                />
                <meta name={'theme-color'} content={'#302ecd'} />
                <meta name={'msapplication-TileColor'} content={'#ffffff'} />
                <meta
                    name={'msapplication-TileImage'}
                    content={'/favicon/ms-icon-144x144.png'}
                />
                <meta name={'theme-color'} content={'#ffffff'} />
                <title>JEP Content Portal</title>
            </Head>
            <RecoilRoot>
                <ConfigProvider theme={THEME}>
                    <ErrorBoundary>
                        <Suspense
                            fallback={
                                <FullScreenLoadingComponent
                                    useWhiteBackground={true}
                                />
                            }
                        >
                            <ErrorBoundary>
                                <Layout className={styles.frameContainer}>
                                    <div className={styles.frameHeader}>
                                        <TopNavigation />
                                    </div>
                                    <LoadingBar />
                                    <div className={styles.frameBody}>
                                        <ErrorBoundary>
                                            <React.Suspense
                                                fallback={
                                                    <LoadingComponent
                                                        useWhiteBackground={
                                                            false
                                                        }
                                                    />
                                                }
                                            >
                                                <Component {...pageProps} />
                                            </React.Suspense>
                                        </ErrorBoundary>
                                    </div>
                                    <Footer />
                                </Layout>
                            </ErrorBoundary>
                        </Suspense>
                    </ErrorBoundary>
                </ConfigProvider>

                <RecoilExternalStatePortal />
            </RecoilRoot>
        </>
    )
}

// prevents SSR
export default dynamic(() => Promise.resolve(ContentFrontend), {
    ssr: false,
})
