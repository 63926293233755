import { selector, atom, selectorFamily } from 'recoil'
import { Set } from 'immutable'
import { ContentPath, hashContentPath } from 'common/src/ContentPath'
import { formulaSheetEntriesHaveUnsavedChangesSelector } from '@/atoms/formulaSheetEntries'

export const unsavedChangesSetAtom = atom<Set<string>>({
    key: 'unsavedChangesSetAtom',
    default: Set(),
})

export const hasUnsavedChangesSelector = selector<boolean>({
    key: 'hasUnsavedChangesSelector',
    get: ({ get }) => {
        return (
            get(unsavedChangesSetAtom).size > 0 ||
            get(formulaSheetEntriesHaveUnsavedChangesSelector)
        )
    },
})

export const specificContentHasUnsavedChangesSelector = selectorFamily<
    boolean,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    SavableContent
>({
    key: 'specificContentHasUnsavedChangesSelector',
    get:
        (savableContent) =>
        ({ get }) => {
            return get(unsavedChangesSetAtom).has(
                hashSavableContent(savableContent)
            )
        },
})

export const specificContentPathHasUnsavedChangesSelectorFamily =
    selectorFamily<boolean, ContentPath>({
        key: 'specificContentPathHasUnsavedChangesSelectorFamily',
        get:
            (contentPath) =>
            ({ get }) => {
                const hashedContentPath = hashContentPath(contentPath)
                return get(unsavedChangesSetAtom).some((hashedSavableContent) =>
                    hashedSavableContent.includes(hashedContentPath)
                )
            },
    })

export enum SavableContentType {
    outline = 'outline',
    summary = 'summary',
    notecard = 'notecard',
    practiceProblem = 'practiceProblem',
    sourceMaterial = 'sourceMaterial',
}

interface BaseSavableContent {
    contentType: SavableContentType
}

export interface SavableOutline extends BaseSavableContent {
    contentType: SavableContentType.outline
    contentPath: ContentPath
}

export interface SavableSummary extends BaseSavableContent {
    contentType: SavableContentType.summary
    contentPath: ContentPath
}

export interface SavableNotecard extends BaseSavableContent {
    contentType: SavableContentType.notecard
    contentPath: ContentPath
    id: string
}

export interface SavablePracticeProblem extends BaseSavableContent {
    contentType: SavableContentType.practiceProblem
    contentPath: ContentPath
    id: string
}

export interface SavableSourceMaterial extends BaseSavableContent {
    contentType: SavableContentType.sourceMaterial
    contentPath: ContentPath
    id: string
}

export type SavableContent =
    | SavableOutline
    | SavableSummary
    | SavableNotecard
    | SavablePracticeProblem
    | SavableSourceMaterial

export const hashSavableContent = (savableContent: SavableContent): string => {
    if (
        savableContent.contentType === SavableContentType.notecard ||
        savableContent.contentType === SavableContentType.practiceProblem ||
        savableContent.contentType === SavableContentType.sourceMaterial
    ) {
        return `${savableContent.contentType}_${hashContentPath(
            savableContent.contentPath
        )}_${savableContent.id}`
    }
    return `${savableContent.contentType}_${hashContentPath(
        savableContent.contentPath
    )}`
}
