import styles from './SomethingWentWrong.module.less'
import React, { ReactElement, useEffect } from 'react'
import {
    SomethingWentWrongProps,
    SOMETHING_WENT_WRONG_GLOBAL_ID,
} from '@/components/somethingWentWrong/SomethingWentWrong.types'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import Link from 'next/link'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { useResetRecoilState } from 'recoil'
import { viewingStateAtom } from '@/atoms/viewingState'
import { ErrorImage } from '@/components/images/ErrorImage'

export const SomethingWentWrong: React.FC<
    SomethingWentWrongProps
> = (): ReactElement => {
    const resetViewingState = useResetRecoilState(viewingStateAtom)
    useEffect(() => {
        resetViewingState()
    }, [resetViewingState])
    return (
        <div
            className={styles.somethingWentWrongPage}
            id={SOMETHING_WENT_WRONG_GLOBAL_ID}
        >
            <div
                className={`${styles.header} ${reusableCssClass.responsiveTextLarge}`}
            >
                Oops. Something went wrong.
            </div>
            <div
                className={`${styles.text} ${reusableCssClass.responsiveTextSmall}`}
            >
                Rest be assured, we will soon be looking into this issue. Feel
                free to give some feedback if this keeps happening, but
                otherwise let&apos;s go&nbsp;
                <Link href={{ pathname: UiLayoutConstant.SPLASH_PAGE }}>
                    <a className={reusableCssClass.clickableTextUnderlined}>
                        home
                    </a>
                </Link>{' '}
                and try again from there.
            </div>
            <ErrorImage />
        </div>
    )
}
