import { DateTime } from 'luxon'

export const displayStringForCommentPostTime = (date: Date): string => {
    const currentTime = DateTime.fromJSDate(new Date())
    const postTime = DateTime.fromJSDate(new Date(date))
    const timeDifferenceInHours = Math.floor(
        currentTime.diff(postTime, 'hours').hours
    )
    if (timeDifferenceInHours > 12) {
        return postTime.toFormat("ccc, LLL dd 'at' hh:mm a")
    }

    if (timeDifferenceInHours === 1) {
        return `1 hour ago`
    }

    if (timeDifferenceInHours > 0) {
        return `${timeDifferenceInHours} hours ago`
    }

    const timeDifferenceInMinutes = Math.floor(
        currentTime.diff(postTime, 'minutes').minutes
    )

    if (timeDifferenceInMinutes === 1) {
        return `1 minute ago`
    }

    if (timeDifferenceInMinutes > 0) {
        return `${timeDifferenceInMinutes} minutes ago`
    }

    const timeDifferenceInSeconds = Math.floor(
        currentTime.diff(postTime, 'seconds').seconds
    )

    if (timeDifferenceInSeconds === 1) {
        return `1 second ago`
    }

    return `${timeDifferenceInSeconds} seconds ago`
}

export const timeSinceInUnits = (
    time: Date,
    units: 'hours' | 'minutes' | 'seconds' | 'milliseconds'
): number => {
    if (time === null) return 99999
    return DateTime.fromJSDate(new Date()).diff(
        DateTime.fromJSDate(time),
        units
    )[units]
}
