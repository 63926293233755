import React, { ReactElement, useMemo, useEffect } from 'react'
import { LoadingBarProps } from './LoadingBar.types'
import NProgress from 'nprogress'
import { useRouter } from 'next/router'
import { useScrollPosition } from '@/hooks/useScrollPosition'
import { useRecoilValue } from 'recoil'
import { isLoggedInAtom } from '@/atoms/isLoggedIn'
import { reusableCssClass } from '@/utils/reusableCssClasses'

const getNavHeight = (): number => {
    return document.getElementsByClassName('nav-menu')[0]?.clientHeight || 0
}

const handleRouteChangeStart = () => NProgress.start()
const handleRouteChangeComplete = () => NProgress.done()
const handleRouteChangeError = () => NProgress.done()

export const LoadingBar: React.FC<LoadingBarProps> = (): ReactElement => {
    const router = useRouter()
    const isLoggedIn = useRecoilValue(isLoggedInAtom)

    useEffect(() => {
        router.events.on('routeChangeStart', handleRouteChangeStart)
        router.events.on('routeChangeComplete', handleRouteChangeComplete)
        router.events.on('routeChangeError', handleRouteChangeError)

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart)
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
            router.events.off('routeChangeError', handleRouteChangeError)
        }
    }, [router.events])

    const { scrollY } = useScrollPosition()
    const repositionClass = useMemo((): string => {
        return scrollY > getNavHeight() ? 'header-not-at-top' : ''
    }, [scrollY])

    return (
        <div
            className={`loading-bar-container ${repositionClass} ${
                !isLoggedIn && reusableCssClass.invisible
            }`}
        />
    )
}
