import { useEffect, useState, useCallback, useMemo } from 'react'
import { getCFPrimarySearchResults } from '@/api/cb/search'
import { SearchContext } from 'common/src/elasticSearch/types'
import {
    CFSearchResponse,
    WBSearchResponse,
} from 'common/src/elasticSearch/responses'
import _ from 'lodash'
import { useRecoilValue } from 'recoil'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'

const DEBOUNCE_WAIT_MS = 1_000
export const useSearch = (
    searchText: string,
    searchContexts: SearchContext[]
): CFSearchResponse => {
    const [searchResults, setSearchResults] = useState<WBSearchResponse>(null)
    const activeCourse = useRecoilValue(frontendDisplayedCourseSelector)

    const debouncedSearch = useMemo(
        () =>
            _.debounce(
                async (
                    searchText: string,
                    searchContexts: SearchContext[],
                    activeCourse: string
                ) => {
                    const response = await getCFPrimarySearchResults(
                        searchText,
                        searchContexts,
                        activeCourse
                    )
                    setSearchResults(response.data.payload)
                },
                DEBOUNCE_WAIT_MS,
                {
                    maxWait: 1000,
                }
            ),
        []
    )
    const doSearchDebounced = useCallback(debouncedSearch, [debouncedSearch])

    useEffect(() => {
        if (!searchText) {
            setSearchResults(null)
            return
        }
        doSearchDebounced(searchText, searchContexts, activeCourse)
    }, [searchText, doSearchDebounced, searchContexts, activeCourse])

    return searchResults
}
