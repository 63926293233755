import React, { ReactElement, useEffect } from 'react'
import { useRouter } from 'next/router'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { SearchBox } from '@/components/search/SearchBox/SearchBox'
import { isSearchBoxOpenAtom } from '@/atoms/isSearchBoxOpen'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Logo } from '@/components/Logo/Logo'
import { frontendLogout } from '@/auth/frontendLogout'
import { ChooseFrontendDisplayedCourse } from '@/components/ChooseFrontendDisplayedCourse/ChooseFrontendDisplayedCourse'
import { useNotifications } from '@/hooks/useNotifications'
import { Badge } from 'antd'
import { BellOutlined } from '@ant-design/icons'
import {
    numUnseenNotificationsSelector,
    isNotificationsModalVisibleAtom,
} from '@/atoms/notifications'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { NotificationModal } from '@/components/NotificationModal/NotificationModal'
import { useRefreshNotificationsByTimestamp } from '@/hooks/useRefreshNotificationsByTimestamp'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { TopNavigationSubProps } from '@/components/layout/TopNavigation/TopNavigation.types'

export const TopNavigationLoggedIn: React.FC<TopNavigationSubProps> = (
    props
): ReactElement => {
    const router = useRouter()
    useEffect(() => {
        if (props.page === UiLayoutConstant.SPLASH_PAGE) {
            router.replace({
                pathname: UiLayoutConstant.PRIMARY_DASHBOARD_PAGE,
            })
            return
        }
    }, [router, props.page])
    const [isSearchBoxOpen, setIsSearchBoxOpen] =
        useRecoilState<boolean>(isSearchBoxOpenAtom)
    useNotifications()
    const numUnseenNotifications = useRecoilValue(
        numUnseenNotificationsSelector
    )
    const setIsNotificationModalVisible = useSetRecoilState<boolean>(
        isNotificationsModalVisibleAtom
    )
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)
    useRefreshNotificationsByTimestamp(courseName)

    return (
        <>
            <div className={'top-navigation'}>
                <Logo />
                <ChooseFrontendDisplayedCourse />
                <SearchBox
                    isVisible={isSearchBoxOpen}
                    setIsVisible={setIsSearchBoxOpen}
                />
                <div
                    className={`notifications-badge-container ${reusableCssClass.centerChildrenVertically}`}
                    onClick={() => setIsNotificationModalVisible(true)}
                >
                    <Badge count={numUnseenNotifications}>
                        <BellOutlined />
                    </Badge>
                </div>
                <div
                    className={'logout-button'}
                    onClick={() => frontendLogout()}
                >
                    Logout
                </div>
            </div>
            <NotificationModal />
        </>
    )
}
