import { ThemeConfig } from 'antd/lib/config-provider/context'

export const THEME: ThemeConfig = {
    token: {
        colorPrimary: '#1b307b',
        colorPrimaryHover: '#afaefe',
        colorLink: '#1890ff',
        colorSuccess: '#52c41a',
        colorWarning: '#faad14',
        colorError: '#f5222d',
        fontSize: 16,
        colorTextHeading: 'rgba(0, 0, 0, 0.85)',
        colorText: 'rgba(0, 0, 0, 0.65)',
        colorTextSecondary: 'rgba(0, 0, 0, 0.45)',
        colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
        borderRadius: 2,
        colorBorder: '#d9d9d9',
        boxShadow:
            '0 0.1875rem 0.375rem -0.25rem rgba(0, 0, 0, 0.12), 0 0.375rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0.5625rem 1.75rem 0.5rem rgba(0, 0, 0, 0, 0.05)',
        fontFamily: "Montserrat, 'DejaVu Sans', Verdana, sans-serif",
    },
}
