import {
    resetRecoilExternalState,
    setRecoilExternalState,
} from '@/components/RecoilExternalStatePortal'
import {
    defaultInvalidationAtom,
    invalidate,
    InvalidationAtom,
} from '@/atoms/types'
import { atomFamily, selectorFamily } from 'recoil'
import { fetchComments } from '@/atoms/contentCommentThreads/utils'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { ContentType } from 'common/src/commentThread/types'
import { GetCommentsResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/responses'

export const practiceProblemCommentThreadAtomFamily = atomFamily<
    GetCommentsResponse | null,
    string
>({
    key: 'practiceProblemCommentThreadAtomFamily',
    default: selectorFamily<GetCommentsResponse | null, string>({
        key: 'practiceProblemCommentThreadAtomFamily',
        get:
            (practiceProblemID) =>
            async ({ get }) => {
                get(
                    practiceProblemCommentThreadInvalidationAtomFamily(
                        practiceProblemID
                    )
                )
                return await fetchComments({
                    contentId: practiceProblemID,
                    contentType: ContentType.PRACTICE_PROBLEM,
                    courseName: get(frontendDisplayedCourseSelector),
                })
            },
    }),
})
export const practiceProblemCommentThreadInvalidationAtomFamily = atomFamily<
    InvalidationAtom,
    string
>({
    key: 'practiceProblemCommentThreadInvalidationAtomFamily',
    default: defaultInvalidationAtom,
    // WARNING: need to reset manually
})
export const invalidatePracticeProblemCommentThread = (
    practiceProblemID: string
): void => {
    setRecoilExternalState(
        practiceProblemCommentThreadInvalidationAtomFamily(practiceProblemID),
        (val) => invalidate(val)
    )
    resetRecoilExternalState(
        practiceProblemCommentThreadAtomFamily(practiceProblemID)
    )
}
