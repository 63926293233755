import React, { ReactElement } from 'react'

import styles from './Footer.module.less'

export const Footer: React.FC<unknown> = (): ReactElement => {
    return (
        <footer className={styles.footer}>
            © 2023 Just Enough Prep All rights reserved.
        </footer>
    )
}
