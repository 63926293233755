import { atom, selector, DefaultValue } from 'recoil'
import { isLoggedInAtom } from '@/atoms/isLoggedIn'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import { LoggerSelectorFamily } from '@/atoms/logger'
import { ContentAuthResponse } from 'common/src/api/contentFrontendVsContentBackend/responses'
import { getAuth } from '@/api/cb/contentCreatorAccount'
import { courseNamesForContentCreatorAtom } from '@/atoms/courseNamesForContentCreator'

export type AuthState = ContentAuthResponse | null

export const fetchAuth = async (): Promise<AuthState> => {
    return (await getAuth()).data.payload
}
export const authStateAtom = atom<AuthState>({
    key: 'authStateAtom',
    default: selector<AuthState>({
        key: 'authDefaultStateFetcher',
        get: async ({ get }) => {
            if (!get(isLoggedInAtom)) return null
            get(LoggerSelectorFamily(authStateAtom.key)).info(
                `Fetching initial auth state`
            )
            return await fetchAuth()
        },
    }),
    effects: [logAtomChangesEffect('authStateAtom')],
})

export const authTimestampAtom = atom<Date>({
    key: 'authTimestampAtom',
    default: null,
})

export const frontendDisplayedCourseSelector = selector<string>({
    key: 'frontendDisplayedCourseSelector',
    get: ({ get }) => {
        if (!get(authStateAtom)) {
            get(LoggerSelectorFamily(frontendDisplayedCourseSelector.key)).info(
                `Attempted to retrieve active course from auth state, but no auth state exists. Returning null instead.`
            )
            return null
        }
        return get(authStateAtom).frontendDisplayedCourseName
    },
    set: ({ set, get }, newFrontendDisplayedCourseName) => {
        if (newFrontendDisplayedCourseName instanceof DefaultValue) {
            return
        }
        set(authStateAtom, (existingAuthState) => ({
            ...existingAuthState,
            frontendDisplayedCourseName: newFrontendDisplayedCourseName,
            isReadOnly: !get(courseNamesForContentCreatorAtom).includes(
                newFrontendDisplayedCourseName
            ),
        }))
    },
})

export const isActiveCourseReadOnlySelector = selector<boolean>({
    key: 'isActiveCourseReadOnlySelector',
    get: ({ get }) => {
        return get(authStateAtom)?.isReadOnly
    },
})
