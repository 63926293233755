import { ContentCreatorCommentNotification } from 'common/src/api/contentFrontendVsContentBackend/types'
import produce from 'immer'

export const markAsSeen = produce(
    (state: ContentCreatorCommentNotification[]): void => {
        state.forEach((notification) => {
            if (!notification.hasBeenSeen) {
                notification.hasBeenSeen = true
            }
        })
    }
)
