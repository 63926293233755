import React, { ReactElement } from 'react'

// Inspiration credit goes to: https://forum.freecodecamp.org/t/newline-in-react-string-solved/68484
interface NewlineTextProps {
    text: string
}
export const NewlineText: React.FC<NewlineTextProps> = (
    props
): ReactElement => {
    const splitText = props.text
        .split('\n')
        .map((line, index) => <p key={index}>{line}</p>)
    return <>{splitText}</>
}
