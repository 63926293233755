import { useEffect } from 'react'

export const useMouseDownHandler = (
    onMouseDown: (event: MouseEvent) => void,
    isMouseDownHandlerActive = true
): void => {
    useEffect(() => {
        if (isMouseDownHandlerActive) {
            document.addEventListener('mousedown', onMouseDown)
        } else {
            document.removeEventListener('mousedown', onMouseDown)
        }
        return () => document.removeEventListener('mousedown', onMouseDown)
    })
}
