import { CancelToken } from 'axios'
import { APIResponseWrapper, WB_BASE_PREFIX } from '@/api/types'
import { callBackend } from '@/api/callBackend'
import { ApiMethod, EmptyRequest, EmptyResponse } from 'common/src/api/types'
import { LoginRequest } from 'common/src/api/websiteFrontendVsWebsiteBackend/authentication/requests'

const PREFIX = WB_BASE_PREFIX + '/authentication'

export const wbLogin = async (
    email: string,
    password: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<LoginRequest, EmptyResponse>(
        PREFIX + '/login',
        ApiMethod.POST,
        { email, password },
        cancelToken
    )
}

export const wbLogout = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<EmptyRequest, EmptyResponse>(
        PREFIX + '/logout',
        ApiMethod.POST,
        null,
        cancelToken
    )
}
