import React, { ReactElement } from 'react'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import styles from './Logo.module.less'
import { LogoProps } from '@/components/Logo/Logo.types'
import { useRouter } from 'next/router'
import { ReadOutlined } from '@ant-design/icons'

export const Logo: React.FC<LogoProps> = (): ReactElement => {
    const router = useRouter()
    return (
        <div
            className={`${styles.siteTitleContainer} ${styles.headerIcon}`}
            onClick={async (): Promise<void> => {
                await router.push({
                    pathname: UiLayoutConstant.PRIMARY_DASHBOARD_PAGE,
                })
            }}
        >
            <span className={styles.siteTitle}>
                <div className={styles.logoContainer}>
                    <ReadOutlined className={styles.siteLogo} />
                </div>
                &nbsp;JEP
            </span>
        </div>
    )
}
