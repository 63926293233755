import { SearchContext } from 'common/src/elasticSearch/types'
import { CancelToken } from 'axios'
import { CFSearchResponse } from 'common/src/elasticSearch/responses'
import { APIResponseWrapper, CB_BASE_PREFIX } from '@/api/types'
import { callBackend } from '@/api/callBackend'
import { CBSearchRequest } from 'common/src/elasticSearch/requests'
import { ApiMethod } from 'common/src/api/types'
import { SpecificContentRequest } from 'common/src/api/core/courses/requests'
import { IGenericPracticeProblem } from 'common/src/practiceProblems/types'
import { ContentPath } from 'common/src/ContentPath'
import {
    GenerateContentFromSourceMaterialAtContentPathRequest,
    GenerateNotecardMnemonicRequest,
} from 'common/src/api/contentFrontendVsContentBackend/requests'
import {
    GenerateNotecardMnemonicResponse,
    GenerateNotecardsFromSourceMaterialAtContentPathResponse,
    GeneratePracticeProblemsFromSourceMaterialAtContentPathResponse,
} from 'common/src/api/contentFrontendVsContentBackend/responses'

const PREFIX = CB_BASE_PREFIX + '/elasticSearch'
export const getCFPrimarySearchResults = async (
    searchText: string,
    searchContexts: SearchContext[],
    courseName?: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<CFSearchResponse> => {
    return await callBackend<CBSearchRequest, CFSearchResponse>(
        PREFIX + '/getCFPrimarySearchResults',
        ApiMethod.GET,
        {
            searchText,
            searchContexts,
            courseName,
        },
        cancelToken
    )
}

export const convertNotecardToPracticeProblem = async (
    courseName: string,
    notecardID: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<IGenericPracticeProblem> => {
    return await callBackend<SpecificContentRequest, IGenericPracticeProblem>(
        PREFIX + '/convertNotecardToPracticeProblem',
        ApiMethod.POST,
        {
            courseName,
            id: notecardID,
        },
        cancelToken
    )
}

export const generatePracticeProblemsFromSourceMaterialAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken = null
): APIResponseWrapper<GeneratePracticeProblemsFromSourceMaterialAtContentPathResponse> => {
    return await callBackend<
        GenerateContentFromSourceMaterialAtContentPathRequest,
        GeneratePracticeProblemsFromSourceMaterialAtContentPathResponse
    >(
        PREFIX + '/generatePracticeProblemsFromSourceMaterialAtContentPath',
        ApiMethod.POST,
        {
            courseName,
            contentPath,
        },
        cancelToken
    )
}

export const generateNotecardsFromSourceMaterialAtContentPath = async (
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken = null
): APIResponseWrapper<GenerateNotecardsFromSourceMaterialAtContentPathResponse> => {
    return await callBackend<
        GenerateContentFromSourceMaterialAtContentPathRequest,
        GenerateNotecardsFromSourceMaterialAtContentPathResponse
    >(
        PREFIX + '/generateNotecardsFromSourceMaterialAtContentPath',
        ApiMethod.POST,
        {
            courseName,
            contentPath,
        },
        cancelToken
    )
}

export const generateNotecardMnemonic = async (
    payload: {
        courseName: string
        notecardID: string
    },
    cancelToken: CancelToken = null
): APIResponseWrapper<GenerateNotecardMnemonicResponse> => {
    return await callBackend<
        GenerateNotecardMnemonicRequest,
        GenerateNotecardMnemonicResponse
    >(
        PREFIX + '/generateNotecardMnemonic',
        ApiMethod.POST,
        {
            courseName: payload.courseName,
            id: payload.notecardID,
        },
        cancelToken
    )
}
