import { FormulaSheetEntry } from 'common/src/formulaSheet/types'
import { atom, selector } from 'recoil'
import { getFormulaSheetEntries } from '@/api/cb/contentCreatorContent'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { Set } from 'immutable'
import { isEqual } from 'lodash'

export const formulaSheetEntriesAtom = atom<FormulaSheetEntry[]>({
    key: 'formulaSheetEntriesAtom',
    default: selector<FormulaSheetEntry[]>({
        key: 'formulaSheetEntriesFetcher',
        get: async ({ get }) => {
            return (
                await getFormulaSheetEntries(
                    get(frontendDisplayedCourseSelector)
                )
            ).data.payload.formulaSheetEntries
        },
    }),
})

export const lastSavedFormulaSheetEntriesAtom = atom<FormulaSheetEntry[]>({
    key: 'lastSavedFormulaSheetEntriesAtom',
    default: [],
})

export const formulaSheetEntriesHaveUnsavedChangesSelector = selector<boolean>({
    key: 'formulaSheetEntriesHaveUnsavedChangesSelector',
    get: ({ get }) => {
        return get(formulaSheetEntriesWithUnsavedChangesSelector).size > 0
    },
})

export const formulaSheetEntriesWithUnsavedChangesSelector = selector<
    Set<string>
>({
    key: 'formulaSheetEntriesWithUnsavedChangesSelector',
    get: ({ get }) => {
        let result = Set<string>()
        for (const [index, formulaSheetEntry] of get(
            formulaSheetEntriesAtom
        ).entries()) {
            if (
                !isEqual(
                    formulaSheetEntry,
                    get(lastSavedFormulaSheetEntriesAtom)[index]
                )
            ) {
                result = result.add(formulaSheetEntry.id)
            }
        }
        return result
    },
})
