import React, { ReactElement, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { notification } from 'antd'
import { useRecoilValue } from 'recoil'
import { isLoggedInAtom } from '@/atoms/isLoggedIn'
import { TopNavigationLoggedIn } from '@/components/layout/TopNavigation/TopNavigationLoggedIn'
import { TopNavigationLoggedOut } from '@/components/layout/TopNavigation/TopNavigationLoggedOut'
import { useRouter } from 'next/router'

export const TopNavigation: React.FC<unknown> = (): ReactElement => {
    const isLoggedIn = useRecoilValue(isLoggedInAtom)
    const router = useRouter()
    const page = useMemo((): string => router.pathname, [router])

    // Pressing esc on any page will destroy any open notification
    useHotkeys('esc', () => {
        setTimeout(notification.destroy, 200)
    })

    if (isLoggedIn) return <TopNavigationLoggedIn page={page} />
    return <TopNavigationLoggedOut page={page} />
}
