import React, {
    ReactElement,
    useState,
    useRef,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import { Modal, Input, InputRef } from 'antd'
import Dropdown from 'antd/lib/dropdown'
import { SearchOutlined } from '@ant-design/icons'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMouseDownHandler } from '@/hooks/useMouseDownHandler'
import {
    SearchBoxProps,
    SEARCH_CONTEXTS,
} from '@/components/search/SearchBox/SearchBox.types'
import { useSearch } from '@/hooks/useSearch'
import { SearchResults } from '@/components/search/SearchBox/searchResults/SearchResults'
import styles from './SearchBox.module.less'

export const SearchBox: React.FC<SearchBoxProps> = (props): ReactElement => {
    const [searchText, setSearchText] = useState<string>('')
    const searchResults = useSearch(searchText, SEARCH_CONTEXTS)
    const [canResultsBeVisible, setCanResultsBeVisible] =
        useState<boolean>(null)
    const searchBoxContainerRef = useRef<HTMLDivElement>(null)
    const searchBoxInnerContainerRef = useRef<HTMLDivElement>(null)

    const searchResultsRef = useRef<HTMLDivElement>(null)

    const [
        searchBoxInnerContainerRefWidth,
        setSearchBoxInnerContainerRefWidth,
    ] = useState<number>(0)

    const searchResultsOverlay = useMemo((): ReactElement => {
        return (
            <div
                className={'search-results-container'}
                ref={searchResultsRef}
                style={{
                    width: searchBoxInnerContainerRefWidth,
                }}
            >
                <SearchResults
                    searchResults={searchResults}
                    closeSearchModal={() => {
                        setCanResultsBeVisible(false)
                        setTimeout(() => props.setIsVisible(false), 200)
                    }}
                />
            </div>
        )
    }, [props, searchBoxInnerContainerRefWidth, searchResults])

    const isMouseOutsideSearchBoxAndResults = useCallback(
        (event: MouseEvent): boolean => {
            return (
                !searchBoxContainerRef.current?.contains(
                    event.target as Node
                ) && !searchResultsRef.current?.contains(event.target as Node)
            )
        },
        [searchBoxContainerRef, searchResultsRef]
    )
    const handleMouseClick = useCallback(
        (event: MouseEvent): void => {
            if (isMouseOutsideSearchBoxAndResults(event)) {
                setCanResultsBeVisible(false)
            }
        },
        [isMouseOutsideSearchBoxAndResults]
    )
    useMouseDownHandler(handleMouseClick, props.isVisible)

    useHotkeys('esc', () => {
        setTimeout(() => {
            setCanResultsBeVisible(false)
        }, 200)
    })

    const searchBoxRef = React.useRef<InputRef>(null)
    useEffect(() => {
        if (props.isVisible) {
            setTimeout(() => {
                searchBoxRef.current.focus()
                setSearchBoxInnerContainerRefWidth(
                    searchBoxInnerContainerRef.current?.clientWidth || 0
                )
            }, 50)
            setTimeout(() => {
                setCanResultsBeVisible(true)
            }, 400)
        } else {
            setCanResultsBeVisible(false)
            searchBoxRef.current?.blur()
        }
    }, [props.isVisible])

    const isDropdownVisible = useMemo(
        (): boolean =>
            canResultsBeVisible &&
            !!searchText &&
            !!searchResults &&
            props.isVisible,
        [canResultsBeVisible, props.isVisible, searchResults, searchText]
    )

    return (
        <>
            <div
                className={`${styles.headerIcon} search-icon-container-mobile-mode`}
                onClick={() => props.setIsVisible(true)}
            >
                <SearchOutlined />
            </div>
            <Modal
                className={'search-box-modal'}
                open={props.isVisible}
                onCancel={() => {
                    setCanResultsBeVisible(false)
                    setTimeout(() => props.setIsVisible(false), 200)
                }}
                closable={false}
                footer={null}
            >
                <div className={'search-box-title-text-container'}>
                    <div className={'search-box-title-text'}>
                        What can I help you find today?
                    </div>
                </div>
                <div
                    className={'search-box-with-button'}
                    ref={searchBoxContainerRef}
                >
                    <Dropdown
                        overlay={searchResultsOverlay}
                        open={isDropdownVisible}
                        placement={'bottomLeft'}
                        overlayClassName={styles.searchResultsDropdownContainer}
                    >
                        <div
                            ref={searchBoxInnerContainerRef}
                            style={{ width: '100%' }}
                        >
                            <Input
                                ref={searchBoxRef}
                                value={searchText}
                                onChange={(event) =>
                                    setSearchText(event.target.value)
                                }
                                size={'large'}
                                onFocus={() =>
                                    setTimeout(
                                        () => setCanResultsBeVisible(true),
                                        200
                                    )
                                }
                            />
                        </div>
                    </Dropdown>
                    <div className={'search-button-in-modal'}>
                        <SearchOutlined />
                    </div>
                </div>
            </Modal>
        </>
    )
}
