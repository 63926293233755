import { useRecoilState, useRecoilValue } from 'recoil'
import {
    notificationsAtom,
    isNotificationsModalVisibleAtom,
} from '@/atoms/notifications'
import { useEffect } from 'react'
import { markAllUnseenNotificationsAsSeenForCourse } from '@/api/cb/notification'
import { useLogger } from '@/hooks/useLogger'
import { markAsSeen } from '@/utils/markAsSeen'
import { ContentCreatorCommentNotification } from 'common/src/api/contentFrontendVsContentBackend/types'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'

export const useNotifications = (): void => {
    const logger = useLogger(useNotifications.name)
    const isNotificationModalVisible = useRecoilValue<boolean>(
        isNotificationsModalVisibleAtom
    )
    const [notifications, setNotifications] =
        useRecoilState<ContentCreatorCommentNotification[]>(notificationsAtom)
    const courseName = useRecoilValue(frontendDisplayedCourseSelector)

    useEffect(() => {
        if (isNotificationModalVisible) {
            setNotifications(markAsSeen(notifications))
            markAllUnseenNotificationsAsSeenForCourse(courseName)
                .then(() =>
                    logger.info(
                        'Notifications modal closed, all notifs marked as seen'
                    )
                )
                .catch((e) => logger.info(`Error marking notifs as seen`, e))
            // Maybe someday we will have events for content creators...
            // registerEvent(PaidUserAnalyticsEventType.OPEN_NOTIFICATIONS_MODAL)
        }
    }, [isNotificationModalVisible])
}
