import { getCommentsForContentTypeAndContentId } from '@/api/wb/commentThread'
import { MinCommentThreadPayload } from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/requests'
import { GetCommentsResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/responses'
import { logger } from '@/logging/FrontendLogger'

export const fetchComments = async (
    payload: MinCommentThreadPayload
): Promise<GetCommentsResponse | null> => {
    const response = await getCommentsForContentTypeAndContentId(payload)
    if (!response.data.success) {
        const isDev = process.env.NEXT_PUBLIC_JEP_MODE !== 'prod'

        if (isDev) {
            logger.info(
                `WB failed, but this is dev, so allowing it to continue.`
            )
            return []
        } else {
            throw new Error('Failed to fetch comments')
        }
    }
    if (response.data.payload === null) {
        logger.info(
            `Received null for content comment payload: ${JSON.stringify(
                payload
            )} - this means the content does not yet exist in WB.`
        )
        return null
    }
    return response.data.payload.sort(
        (commentA, commentB) =>
            new Date(commentA.createdAt).getTime() -
            new Date(commentB.createdAt).getTime()
    )
}
