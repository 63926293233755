import { atom, selector } from 'recoil'
import { authStateAtom } from '@/atoms/auth'
import { getAllNotificationsForCourse } from '@/api/cb/notification'
import { ContentCreatorCommentNotification } from 'common/src/api/contentFrontendVsContentBackend/types'

export const notificationsAtom = atom<
    ContentCreatorCommentNotification[] | null
>({
    key: 'notificationsAtom',
    default: selector<ContentCreatorCommentNotification[]>({
        key: 'notificationsAtomFetcher',
        get: async ({ get }) => {
            if (!get(authStateAtom)) return null
            const authState = get(authStateAtom)
            return await fetchNotifications(
                authState.frontendDisplayedCourseName
            )
        },
    }),
})

export const numUnseenNotificationsSelector = selector<number>({
    key: 'numUnseenNotificationsSelector',
    get: ({ get }) => {
        return (
            get(notificationsAtom)?.filter(
                (notification) => !notification.hasBeenSeen
            )?.length ?? 0
        )
    },
})

export const notificationsTimestampAtom = atom<Date>({
    key: 'notificationsTimestampAtom',
    default: new Date(),
})

export const fetchNotifications = async (
    courseName: string
): Promise<ContentCreatorCommentNotification[]> => {
    return (await getAllNotificationsForCourse(courseName)).data.payload
}

export const isNotificationsModalVisibleAtom = atom<boolean>({
    key: 'isNotificationsModalVisibleAtom',
    default: false,
})
