import { atom, selector } from 'recoil'
import { getIsLoggedIn } from '@/api/cb/contentFrontendAuthentication'
import { logAtomChangesEffect } from '@/atoms/atomEffects/logAtomChangesEffect'
import { LoggerSelectorFamily } from '@/atoms/logger'

export const isLoggedInAtom = atom<boolean>({
    key: 'isLoggedInAtom',
    default: selector<boolean>({
        key: 'isLoggedInDefaultStateFetcher',
        get: async ({ get }) => {
            get(LoggerSelectorFamily(isLoggedInAtom.key)).info(
                `Retrieving initial logged in state`
            )
            return await fetchIsLoggedIn()
        },
    }),
    effects: [logAtomChangesEffect('isLoggedInAtom')],
})

export const isLoggedInTimestampAtom = atom<Date>({
    key: 'isLoggedInTimestampAtom',
    default: null,
})

export const fetchIsLoggedIn = async (): Promise<boolean> => {
    return (await getIsLoggedIn()).data.payload.isLoggedIn
}
