import {
    resetRecoilExternalState,
    setRecoilExternalState,
} from '@/components/RecoilExternalStatePortal'
import {
    defaultInvalidationAtom,
    invalidate,
    InvalidationAtom,
} from '@/atoms/types'
import { atomFamily, selectorFamily } from 'recoil'
import { fetchComments } from '@/atoms/contentCommentThreads/utils'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { ContentType } from 'common/src/commentThread/types'
import { GetCommentsResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/commentThread/responses'

export const notecardCommentThreadAtomFamily = atomFamily<
    GetCommentsResponse | null,
    string
>({
    key: 'notecardCommentThreadAtomFamily',
    default: selectorFamily<GetCommentsResponse | null, string>({
        key: 'notecardCommentThreadAtomFamily',
        get:
            (notecardID) =>
            async ({ get }) => {
                get(notecardCommentThreadInvalidationAtomFamily(notecardID))
                return await fetchComments({
                    contentId: notecardID,
                    contentType: ContentType.NOTECARD,
                    courseName: get(frontendDisplayedCourseSelector),
                })
            },
    }),
})
export const notecardCommentThreadInvalidationAtomFamily = atomFamily<
    InvalidationAtom,
    string
>({
    key: 'notecardCommentThreadInvalidationAtomFamily',
    default: defaultInvalidationAtom,
    // WARNING: need to reset manually
})
export const invalidateNotecardCommentThread = (notecardID: string): void => {
    setRecoilExternalState(
        notecardCommentThreadInvalidationAtomFamily(notecardID),
        (val) => invalidate(val)
    )
    resetRecoilExternalState(notecardCommentThreadAtomFamily(notecardID))
}
