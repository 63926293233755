import { JEPNotificationCommentMetadata } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import { ContentType } from 'common/src/commentThread/types'
import { ViewingStateType } from '@/atoms/viewingState'
import assertNever from 'assert-never/index'
import {
    ContentCreatorAnnouncementNotification,
    ContentCreatorCommentNotification,
} from 'common/src/api/contentFrontendVsContentBackend/types'
import { invalidateNotecardCommentThread } from '@/atoms/contentCommentThreads/notecardCommentThreads'
import { invalidatePracticeProblemCommentThread } from '@/atoms/contentCommentThreads/practiceProblemCommentThreads'
import { UrlObject } from 'url'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { getRouterQueryFromViewingState } from '@/hooks/useViewingState'
import { ContentPath } from 'common/src/ContentPath'
import { decodeOutlineCommentData } from 'common/src/commentThread/OutlineCommentData'

export interface NotificationListItemProps {
    setIsModalVisible: (isVisible: boolean) => void
    notification:
        | ContentCreatorCommentNotification
        | ContentCreatorAnnouncementNotification
}

export const getViewingStateTypeForContentType = (
    contentType: ContentType
): ViewingStateType => CONTENT_TYPE_TO_VIEWING_STATE_TYPE_MAP.get(contentType)

const CONTENT_TYPE_TO_VIEWING_STATE_TYPE_MAP = new Map<
    ContentType,
    ViewingStateType
>([
    [ContentType.NOTECARD, ViewingStateType.notecards],
    [ContentType.PRACTICE_PROBLEM, ViewingStateType.practiceProblems],
])

export const invalidateContentCommentThread = (
    commentNotificationMetadata: JEPNotificationCommentMetadata
): void => {
    switch (commentNotificationMetadata.contentType) {
        case ContentType.NOTECARD: {
            invalidateNotecardCommentThread(
                commentNotificationMetadata.contentId
            )
            break
        }
        case ContentType.PRACTICE_PROBLEM: {
            invalidatePracticeProblemCommentThread(
                commentNotificationMetadata.contentId
            )
            break
        }
        case ContentType.OUTLINE:
        case ContentType.SUMMARY: {
            // Currently doing nothing as it is currently always invalidated
            break
        }
        default:
            assertNever(commentNotificationMetadata.contentType)
    }
}

export const prettifyContentType = (contentType: ContentType): string => {
    switch (contentType) {
        case ContentType.PRACTICE_PROBLEM:
            return 'a problem'
        case ContentType.NOTECARD:
            return 'a notecard'
        case ContentType.OUTLINE:
            return 'an outline'
        case ContentType.SUMMARY:
            return 'a summary'
        default:
            assertNever(contentType)
    }
}

export const buildHrefForCommentContentTypeAndId = (
    contentType: ContentType,
    contentPath: ContentPath,
    contentId: string
): UrlObject | string => {
    switch (contentType) {
        case ContentType.PRACTICE_PROBLEM:
        case ContentType.NOTECARD:
            return {
                pathname: UiLayoutConstant.PRIMARY_DASHBOARD_PAGE,
                query: {
                    ...getRouterQueryFromViewingState({
                        contentPath,
                        viewingStateType:
                            getViewingStateTypeForContentType(contentType),
                        id: contentId,
                    }),
                    showCommentThread: true,
                },
            }
        case ContentType.SUMMARY:
        case ContentType.OUTLINE: {
            const { contentPath, type } = decodeOutlineCommentData(contentId)
            return {
                pathname: UiLayoutConstant.PRIMARY_DASHBOARD_PAGE,
                query: getRouterQueryFromViewingState({
                    contentPath,
                    viewingStateType: type as ViewingStateType,
                    id: null,
                }),
            }
        }
        default:
            assertNever(contentType)
    }
}
