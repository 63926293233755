/**
 * Monitors changes to url / viewing state and updates viewing state accordingly
 */
import { useRouter } from 'next/router'
import {
    ViewingState,
    viewingStateAtom,
    ViewingStateType,
} from '@/atoms/viewingState'
import {
    areContentPathsEqual,
    hashContentPath,
    unHashContentPath,
} from 'common/src/ContentPath'
import { ParsedUrlQuery } from 'querystring'
import { useRecoilState } from 'recoil'
import { useEffect, useRef } from 'react'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'

export const useViewingState = (): void => {
    const router = useRouter()
    const [viewingState, setViewingState] = useRecoilState(viewingStateAtom)
    const isFirstLoad = useRef<boolean>(true)

    // react to changes in router query, updating viewing state
    useEffect(() => {
        const routerViewingState = getViewingStateFromRouterQuery(router.query)
        if (!areViewingStatesEqual(viewingState, routerViewingState)) {
            setViewingState(routerViewingState)
        }
    }, [router.query])

    // react to changes in viewing state, updating router query
    useEffect(() => {
        if (isFirstLoad.current) {
            isFirstLoad.current = false
            return
        }
        const routerViewingState = getViewingStateFromRouterQuery(router.query)
        if (!areViewingStatesEqual(viewingState, routerViewingState)) {
            router.push({
                pathname: UiLayoutConstant.PRIMARY_DASHBOARD_PAGE,
                query: getRouterQueryFromViewingState(viewingState),
            })
        }
    }, [viewingState])
}

const getViewingStateFromRouterQuery = (
    query: ParsedUrlQuery
): ViewingState => ({
    contentPath: query.contentPath
        ? unHashContentPath(query.contentPath as string)
        : [],
    viewingStateType: (query.viewingStateType as ViewingStateType) ?? null,
    id: (query.id as string) ?? null,
})

export const getRouterQueryFromViewingState = (
    viewingState: ViewingState
): ParsedUrlQuery => ({
    contentPath: viewingState.contentPath
        ? hashContentPath(viewingState.contentPath)
        : undefined,
    viewingStateType: viewingState.viewingStateType ?? undefined,
    id: viewingState.id ?? undefined,
})

const areViewingStatesEqual = (
    viewingStateA: ViewingState,
    viewingStateB: ViewingState
): boolean => {
    return (
        viewingStateA.viewingStateType === viewingStateB.viewingStateType &&
        areContentPathsEqual(
            viewingStateA.contentPath,
            viewingStateB.contentPath
        ) &&
        viewingStateA.id === viewingStateB.id
    )
}
