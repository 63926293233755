import { atom, selector } from 'recoil'
import { getCourseNamesForContentCreator } from '@/api/cb/contentCreatorAccount'

export const courseNamesForContentCreatorAtom = atom<string[]>({
    key: 'courseNamesForContentCreatorAtom',
    default: selector<string[]>({
        key: 'courseNamesForContentCreatorFetcher',
        get: async () => {
            const response = await getCourseNamesForContentCreator()
            return response.data.payload
        },
    }),
})
