import {
    AdditionalLogInfo,
    isStackIStackFrame,
    LogLevel,
    LogObject,
    LogSource,
    SessionData,
} from 'common/src/BackendLogger/BackendLogger.types'
import { ErrorInfo } from 'react'
import {
    buildLogTransport,
    stackToString,
} from 'common/src/BackendLogger/BuildLogTransport'
import { getGlobalJepMode, JEP_MODE } from 'common/src/utils/jepMode'
import { getRecoilExternalLoadable } from '@/components/RecoilExternalStatePortal'
import { sessionDataAtom } from '@/atoms/sessionData'
import { nanoid } from 'nanoid'
import { DISCORD_JEPGINEER_ROLE_ID } from 'common/src/consts'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AsciiTable from 'ascii-table'
import axios, { AxiosError, AxiosInstance } from 'axios'

const logTransport = buildLogTransport()
const jepMode = getGlobalJepMode()

// used for finding logs at the very beginning of the load (before other information is available)
const frontendSessionID = nanoid(5)
interface AdditionalFrontendLogData {
    stackAsString?: string
    requestID?: string
}

// highly similar to wf variant
class FrontendLoggerSingleton {
    private readonly jepMode: JEP_MODE
    private readonly shouldLogToConsole: boolean
    private readonly discordAxios: AxiosInstance | null

    constructor(
        private readonly instanceName: string | null = null,
        private readonly getSessionData: () => SessionData = () => {
            const loadableSessionData =
                getRecoilExternalLoadable(sessionDataAtom)
            if (loadableSessionData?.state === 'hasValue') {
                return { ...loadableSessionData.contents, frontendSessionID }
            }
            return { frontendSessionID }
        }
    ) {
        this.instanceName = instanceName
        this.jepMode = getGlobalJepMode()
        this.shouldLogToConsole = this.jepMode !== JEP_MODE.prod
        this.discordAxios = process.env.NEXT_PUBLIC_ERROR_WEBHOOK_BASE_URL
            ? axios.create({
                  baseURL: process.env.NEXT_PUBLIC_ERROR_WEBHOOK_BASE_URL,
                  headers: {
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                  },
              })
            : null

        if (this.shouldLogToConsole) {
            console.log(
                `New logger with instance name ${
                    instanceName ?? 'default'
                } instantiated`
            )
        }
    }

    private processMsg(
        msgOrError: string | Error,
        prependString: string
    ): string {
        let msg: string =
            msgOrError instanceof Error ? msgOrError.message : msgOrError
        msg = FrontendLoggerSingleton.prependMessage(msg, prependString)
        return FrontendLoggerSingleton.prependMessage(msg, this.instanceName)
    }

    public info = (message: string, options: FrontendLogOptions): void => {
        const processedMessage = this.processMsg(message, options.prependString)
        if (this.shouldLogToConsole) {
            console.log(processedMessage)
        }
        if (options?.canSendToBackend ?? true) {
            this.pushLogToStash(processedMessage, LogLevel.INFO, {
                requestID: options.requestID,
            })
        }
        return
    }

    public warn = (message: string, options?: FrontendLogOptions): void => {
        const processedMessage = this.processMsg(message, options.prependString)
        if (this.shouldLogToConsole) {
            console.warn(processedMessage)
        }
        if (options?.canSendToBackend ?? true) {
            this.pushLogToStash(processedMessage, LogLevel.WARN, {
                requestID: options.requestID,
            })
        }
        return
    }

    public error = (
        message: string | Error,
        options?: FrontendLogOptions
    ): void => {
        const processedMessage = this.processMsg(message, options.prependString)
        if (this.shouldLogToConsole) {
            console.error(processedMessage)
        }
        if (options?.canSendToBackend ?? true) {
            this.pushLogToStash(processedMessage, LogLevel.ERROR, {
                stackAsString:
                    message instanceof Error ? message.stack : undefined,
                requestID: options.requestID,
            })
        }
        return
    }

    public reactError = (error: Error, errorInfo: ErrorInfo): void => {
        this.pushLogToStash(error, LogLevel.ERROR, {
            stackAsString: errorInfo.componentStack,
        })
    }

    public debug = (message: string, options?: FrontendLogOptions): void => {
        const processedMessage = this.processMsg(message, options.prependString)
        if (this.shouldLogToConsole) {
            console.debug(processedMessage)
        }
        if (options?.canSendToBackend ?? true) {
            this.pushLogToStash(processedMessage, LogLevel.DEBUG, {
                requestID: options.requestID,
            })
        }
        return
    }

    private static prependMessage = (
        message: string,
        prependString: string | null
    ): string => {
        return prependString ? `[${prependString}] ${message}` : message
    }

    private pushLogToStash = (
        messageOrError: unknown,
        logLevel: LogLevel,
        additionalFrontendLogData: AdditionalFrontendLogData = {}
    ): void => {
        const logObject = this.buildLogObject(
            logLevel,
            messageOrError,
            additionalFrontendLogData
        )
        logTransport(logObject)
        if (logLevel === LogLevel.ERROR) {
            // intentionally not awaited
            this.handleError(logObject)
        }
    }

    private buildAdditionalLogInfo = (): AdditionalLogInfo => ({
        page:
            typeof window === 'undefined'
                ? 'SERVER_SIDE'
                : window?.location?.href ?? 'SERVER_SIDE',
        userAgent:
            typeof navigator === 'undefined'
                ? 'SERVER_SIDE'
                : navigator?.userAgent ?? 'SERVER_SIDE',
    })

    private buildLogObject = (
        logLevel: LogLevel,
        msgOrError: string | Error | unknown,
        additionalFrontendLogData: AdditionalFrontendLogData
    ): LogObject => {
        let msg: string
        let error: Error | undefined
        if (msgOrError instanceof Error) {
            msg = msgOrError.message
            error = msgOrError
        } else if (typeof msgOrError === 'string') {
            msg = msgOrError
        } else {
            msg = msgOrError as string
        }

        const additionalLogInfo = this.buildAdditionalLogInfo()
        const date = new Date()

        const sessionData = this.getSessionData()
        return {
            isError: !!error,
            logLevel,
            msg,
            requestID: additionalFrontendLogData.requestID,
            ...sessionData,
            date,
            source: LogSource.CONTENT_FRONTEND,
            instanceName: this.instanceName,
            stackString: additionalFrontendLogData.stackAsString,
            error,
            page: additionalLogInfo?.page,
            userAgent: additionalLogInfo?.userAgent,
            jepMode,
        }
    }

    private handleError = async (logObject: LogObject): Promise<void> => {
        const errorMsgs = this.buildErrorMsgsForDiscord(logObject)
        for (const errorMsg of errorMsgs) {
            await this.sendMessageToDiscord(errorMsg)
        }
    }

    private sendMessageToDiscord = async (msg: string): Promise<void> => {
        if (!this.discordAxios) {
            this.warn(
                'No error webhook base url is set. Not sending a discord error notification.'
            )
            return
        }

        const discordLengthLimit = 2_000
        if (msg.length > discordLengthLimit) {
            logger.warn(`Trying to send a message that is too long!`)
        }

        const maxAttempts = 10
        const msgToSend = msg.slice(0, discordLengthLimit)
        for (
            let attemptNumber = 0;
            attemptNumber < maxAttempts;
            attemptNumber++
        ) {
            try {
                await this.discordAxios.post(
                    '',
                    { content: msgToSend },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                        },
                    }
                )
                return
            } catch (error) {
                if (
                    error instanceof AxiosError &&
                    error.response.status === 429
                ) {
                    const sleepDurationMs = 3_000 * (attemptNumber + 1)
                    logger.warn(
                        `Encountered too many requests from discord (attempt number: ${attemptNumber + 1} / ${maxAttempts}). Sleeping: ${sleepDurationMs}ms before trying again...`
                    )
                    await new Promise((r) => setTimeout(r, sleepDurationMs))
                } else {
                    break
                }
            }
        }
        logger.warn(`Failed to post message to discord: ${msgToSend}`)
    }

    private buildErrorMsgsForDiscord(logObject: LogObject): string[] {
        /*
        Outputs a message with the following structure:
            1. Header
            2. Relevant ID detail on user / course / request / session, etc.
         */

        // Header
        let header = ''
        if (this.jepMode === JEP_MODE.prod) {
            header += `<@&${DISCORD_JEPGINEER_ROLE_ID}> `
        }
        header += `---\nAn error happened in ${this.jepMode}. See below for additional detail:\n\n`

        // Relevant ID detail on user, etc.
        const table = new AsciiTable(logObject.msg)
        table.addRow('Source', `${logObject.instanceName}@${logObject.source}`)
        if (logObject.page) table.addRow('Page', logObject.page)
        if (logObject.userAgent) table.addRow('User Agent', logObject.userAgent)
        if (logObject.email) table.addRow('Email', logObject.email)
        if (logObject.userID) table.addRow('User ID', logObject.userID)
        if (logObject.contentCreatorID)
            table.addRow('Content Creator ID', logObject.contentCreatorID)
        if (logObject.courseID) table.addRow('Course ID', logObject.courseID)
        if (logObject.courseName)
            table.addRow('Course Name', logObject.courseName)
        if (logObject.sessionID) table.addRow('Session ID', logObject.sessionID)
        if (logObject.frontendSessionID)
            table.addRow('Frontend Session ID', logObject.frontendSessionID)
        const tableText = '```\n' + table.toString() + '```\n'

        const result = [header, tableText]
        if (logObject.stackFrame) {
            // Stack
            let formattedStackText: string
            if (isStackIStackFrame(logObject.stackFrame)) {
                formattedStackText = stackToString(logObject.stackFrame)
            } else {
                formattedStackText = logObject.stackFrame
            }
            const stackText = '```' + formattedStackText + '```\n'
            result.push(stackText)
        }

        return result
    }
}

const frontendLoggerSingleton = new FrontendLoggerSingleton()

export interface FrontendLogOptions {
    canSendToBackend?: boolean
    prependString?: string
    requestID?: string
}

export interface FrontendLogger {
    debug: (message: string, options?: FrontendLogOptions) => void

    info: (message: string, options?: FrontendLogOptions) => void

    warn: (message: string, options?: FrontendLogOptions) => void

    error: (
        messageOrError: string | Error,
        options?: FrontendLogOptions
    ) => void

    reactError: (error: Error, errorInfo: ErrorInfo) => void
}

export const buildFrontendLogger = (
    instanceName: string | null = null
): FrontendLogger => ({
    info: (message: string, options?: FrontendLogOptions): void =>
        frontendLoggerSingleton.info(message, {
            prependString: instanceName,
            ...options,
        }),
    warn: (message: string, options?: FrontendLogOptions): void =>
        frontendLoggerSingleton.warn(message, {
            prependString: instanceName,
            ...options,
        }),
    debug: (message: string, options?: FrontendLogOptions): void =>
        frontendLoggerSingleton.debug(message, {
            prependString: instanceName,
            ...options,
        }),
    error: (
        messageOrError: string | Error,
        options?: FrontendLogOptions
    ): void =>
        frontendLoggerSingleton.error(messageOrError, {
            prependString: instanceName,
            ...options,
        }),
    reactError: (error: Error, errorInfo: ErrorInfo): void =>
        frontendLoggerSingleton.reactError(error, errorInfo),
})

export const logger = buildFrontendLogger()
