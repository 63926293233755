import { atom, selector } from 'recoil'
import { getAllCourseNames } from '@/api/cb/contentCreatorAccount'

export const courseNamesAtom = atom<string[]>({
    key: 'courseNamesAtom',
    default: selector<string[]>({
        key: 'courseNamesFetcher',
        get: async () => {
            const response = await getAllCourseNames()
            return response.data.payload
        },
    }),
})
