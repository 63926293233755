import styles from './ChooseFrontendDisplayedCourse.module.less'
import React, { ReactElement, useState, useCallback, useMemo } from 'react'
import { ChooseFrontendDisplayedCourseProps } from './ChooseFrontendDisplayedCourse.types'
import { Select, Modal } from 'antd'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { courseNamesForContentCreatorAtom } from '@/atoms/courseNamesForContentCreator'
import { frontendDisplayedCourseSelector } from '@/atoms/auth'
import { setFrontendActiveCourseForContentCreator } from '@/api/cb/contentCreatorAccount'
import { viewingStateAtom } from '@/atoms/viewingState'
import { globalIsLoadingAtom } from '@/atoms/globalIsLoading'
import { syncSuccessMessage, syncErrorMessage } from '@/utils/syncMessages'
import { hasUnsavedChangesSelector } from '@/atoms/unsavedChanges'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { courseNamesAtom } from '@/atoms/courseNames'

const { Option } = Select
export const ChooseFrontendDisplayedCourse: React.FC<
    ChooseFrontendDisplayedCourseProps
> = (): ReactElement => {
    const contentCreatorCourseNames = useRecoilValue(
        courseNamesForContentCreatorAtom
    )
    const allCourseNames = useRecoilValue(courseNamesAtom)
    const nonContentCreatorCourseNames = useMemo(
        (): string[] =>
            allCourseNames.filter(
                (courseName) => !contentCreatorCourseNames.includes(courseName)
            ),
        [contentCreatorCourseNames, allCourseNames]
    )

    const frontendDisplayedActiveCourse = useRecoilValue(
        frontendDisplayedCourseSelector
    )
    const [newlySelectedCourseName, setNewlySelectedCourseName] =
        useState<string>(frontendDisplayedActiveCourse)

    const resetViewingState = useResetRecoilState(viewingStateAtom)
    const hasUnsavedChanges = useRecoilValue(hasUnsavedChangesSelector)

    const [shouldShowAreYouSureModal, setShouldShowAreYouSureModal] =
        useState<boolean>(false)

    const setGlobalIsLoading = useSetRecoilState(globalIsLoadingAtom)

    const changeCourses = useCallback(
        async (newCourseName: string): Promise<void> => {
            setGlobalIsLoading(true)
            resetViewingState()
            const response =
                await setFrontendActiveCourseForContentCreator(newCourseName)
            if (response.data.isError) {
                syncErrorMessage(
                    `Unable to change active course. Please refresh and try again.`
                )
            } else {
                syncSuccessMessage(
                    `Successfully changed active course to: ${newCourseName}`,
                    1
                )
                // resets recoil by doing a hard refresh
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.location = window.location.origin
            }
            // setGlobalIsLoading(false) // better to not do this, makes the transition more seamless
        },
        [resetViewingState, setGlobalIsLoading]
    )

    const handleChange = useCallback(
        (newCourseName: string): void => {
            setNewlySelectedCourseName(newCourseName)
            if (hasUnsavedChanges) {
                setShouldShowAreYouSureModal(true)
                return
            }
            if (newCourseName !== frontendDisplayedActiveCourse) {
                changeCourses(newCourseName)
            }
        },
        [changeCourses, frontendDisplayedActiveCourse, hasUnsavedChanges]
    )

    return (
        <div className={styles.chooseFrontendDisplayedCourse}>
            <span className={styles.chooseFrontendDisplayedCourseTitle}>
                Change Active Course
            </span>
            <Select
                value={frontendDisplayedActiveCourse}
                onChange={handleChange}
                className={
                    hasUnsavedChanges ? reusableCssClass.hasUnsavedChanges : ''
                }
                dropdownMatchSelectWidth={false}
            >
                {[
                    ...contentCreatorCourseNames,
                    ...nonContentCreatorCourseNames,
                ].map((courseName) => (
                    <Option
                        value={courseName}
                        key={courseName}
                        className={
                            hasUnsavedChanges &&
                            courseName === frontendDisplayedActiveCourse
                                ? reusableCssClass.hasUnsavedChanges
                                : ''
                        }
                    >
                        {`${courseName}${
                            nonContentCreatorCourseNames.includes(courseName)
                                ? ' (read-only)'
                                : ''
                        }`}
                    </Option>
                ))}
            </Select>
            <Modal
                open={shouldShowAreYouSureModal}
                onCancel={() => {
                    setShouldShowAreYouSureModal(false)
                    setNewlySelectedCourseName(frontendDisplayedActiveCourse)
                }}
                onOk={() => {
                    setShouldShowAreYouSureModal(false)
                    changeCourses(newlySelectedCourseName)
                }}
            >
                Are you sure you want to change courses? You currently have
                unsaved changes, which will be lost upon changing courses.
            </Modal>
        </div>
    )
}
