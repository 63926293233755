import { SearchContext } from 'common/src/elasticSearch/types'

export interface SearchBoxProps {
    isVisible: boolean
    setIsVisible: (isVisible: boolean) => void
}

export const SEARCH_CONTEXTS: SearchContext[] = [
    'outlines',
    'notecards',
    'practiceProblems',
    'sourceMaterials',
    'contentIds',
    'other',
]
