import { useRefreshDataByTimestamp } from '@/hooks/useRefreshDataByTimestamp'
import {
    notificationsAtom,
    notificationsTimestampAtom,
    fetchNotifications,
} from '@/atoms/notifications'

export const useRefreshNotificationsByTimestamp = (
    courseName: string
): void => {
    useRefreshDataByTimestamp(
        notificationsAtom,
        notificationsTimestampAtom,
        () => fetchNotifications(courseName),
        'Notifications on course',
        1,
        false,
        true
    )
}
