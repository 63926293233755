import { displayStringForCommentPostTime } from '@/utils/dateTime'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { Avatar } from 'antd'
import { JEPNotificationType } from 'common/src/api/websiteFrontendVsWebsiteBackend/notifications/types'
import React, { ReactElement, useMemo } from 'react'
import { CommentOutlined, SoundOutlined } from '@ant-design/icons'
import {
    NotificationListItemProps,
    invalidateContentCommentThread,
    prettifyContentType,
    buildHrefForCommentContentTypeAndId,
} from '@/components/NotificationModal/NotificationListItem/NotificationListItem.types'
import Link from 'next/link'
import assert from 'assert'
import styles from './NotificationListItem.module.less'
import { UrlObject } from 'url'
import assertNever from 'assert-never/index'
import { UserType } from 'magic-text-box-lexical/src/context/AnnotatedContentContext/AnnotatedContent.types'

export const NotificationListItem: React.FC<NotificationListItemProps> = (
    props
): ReactElement => {
    const { notification } = props
    const { avatar, href, textToShow } = useMemo((): {
        avatar: ReactElement
        href: string | UrlObject
        textToShow: ReactElement
    } => {
        assert(
            [
                JEPNotificationType.COMMENT,
                JEPNotificationType.ANNOUNCEMENT,
            ].includes(notification.type)
        )
        switch (notification.type) {
            case JEPNotificationType.COMMENT:
                return {
                    avatar: (
                        <Avatar
                            className={`${styles.avatar} ${
                                notification.metadata.commentUserType ===
                                UserType.instructor
                                    ? styles.avatarInstructor
                                    : styles.avatarStudent
                            }`}
                            size="large"
                            icon={<CommentOutlined />}
                        />
                    ),
                    href: buildHrefForCommentContentTypeAndId(
                        notification.metadata.contentType,
                        notification.metadata.contentPath,
                        notification.metadata.contentId
                    ),
                    textToShow: (
                        <div>
                            <b>
                                {notification.metadata.commentUserDisplayName}
                            </b>{' '}
                            has commented on{' '}
                            {prettifyContentType(
                                notification.metadata.contentType
                            )}
                            .
                        </div>
                    ),
                }
            case JEPNotificationType.ANNOUNCEMENT:
                return {
                    avatar: (
                        <Avatar
                            size={'large'}
                            className={'avatar avatar-announcement'}
                            icon={<SoundOutlined />}
                        />
                    ),
                    href: notification.metadata.externalHref,
                    textToShow: (
                        <div>
                            <b>Announcement: </b>
                            {notification.metadata.text}
                        </div>
                    ),
                }
            default:
                return assertNever(notification)
        }
    }, [notification])

    return (
        <Link
            href={href}
            className={reusableCssClass.clickMe}
            onClick={() => {
                if (notification.type === JEPNotificationType.COMMENT) {
                    invalidateContentCommentThread(notification.metadata)
                }
                props.setIsModalVisible(false)
                // Maybe someday we will have events for content creators...
                // registerEvent(
                //     PaidUserAnalyticsEventType.CLICK_NOTIFICATION,
                //     {
                //         type: notification.type,
                //     }
                // )
            }}
        >
            <div className={styles.notificationListItemContainer}>
                <div className={styles.notificationListItemAvatar}>
                    {avatar}
                </div>
                <div
                    className={`${styles.notificationListItemTextContainer} ${reusableCssClass.centerChildrenVertically}`}
                >
                    <div className={styles.notificationListItemTextInfo}>
                        {textToShow}
                    </div>
                    <div className={styles.notificationListItemTextTime}>
                        {displayStringForCommentPostTime(
                            notification.createdAt
                        )}
                    </div>
                </div>
                <div
                    className={`${styles.notificationListItemDot} ${
                        notification.hasBeenSeen ? styles.hidden : ''
                    }`}
                >
                    &#9679;
                </div>
            </div>
        </Link>
    )
}
