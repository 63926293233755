import { callBackend } from '@/api/callBackend'
import {
    LoginRequest,
    SendPasswordRecoveryEmailRequest,
} from 'common/src/api/websiteFrontendVsWebsiteBackend/authentication/requests'
import { ResetPasswordRequest } from 'common/src/api/contentFrontendVsContentBackend/requests'
import { ApiMethod, EmptyRequest, EmptyResponse } from 'common/src/api/types'
import { CancelToken } from 'axios'
import { IsLoggedInResponse } from 'common/src/api/websiteFrontendVsWebsiteBackend/authentication/responses'
import { APIResponseWrapper, CB_BASE_PREFIX } from '@/api/types'
import { ContentAuthResponse } from 'common/src/api/contentFrontendVsContentBackend/responses'
import { ContentSignupRequest } from 'common/src/api/contentFrontendVsContentBackend/requests'

const PREFIX = CB_BASE_PREFIX + '/contentFrontendAuthentication'

export const logout = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<EmptyRequest, EmptyResponse>(
        PREFIX + '/logout',
        ApiMethod.POST,
        null,
        cancelToken
    )
}

export const getSessionID = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<{ sessionID: string }> => {
    return await callBackend<EmptyRequest, { sessionID: string }>(
        PREFIX + '/getSessionID',
        ApiMethod.GET,
        null,
        cancelToken
    )
}

export const getIsLoggedIn = async (
    cancelToken: CancelToken = null
): APIResponseWrapper<IsLoggedInResponse> => {
    return await callBackend<EmptyRequest, IsLoggedInResponse>(
        PREFIX + '/getIsLoggedIn',
        ApiMethod.GET,
        null,
        cancelToken
    )
}

export const cbLogin = async (
    email: string,
    password: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<ContentAuthResponse> => {
    return await callBackend<LoginRequest, ContentAuthResponse>(
        PREFIX + '/login',
        ApiMethod.POST,
        {
            email,
            password,
        },
        cancelToken
    )
}

export const signup = async (
    payload: ContentSignupRequest,
    cancelToken: CancelToken = null
): APIResponseWrapper<ContentAuthResponse> => {
    return await callBackend<ContentSignupRequest, ContentAuthResponse>(
        PREFIX + '/signup',
        ApiMethod.POST,
        payload,
        cancelToken
    )
}
export const sendPasswordRecoveryEmail = async (
    email: string
): APIResponseWrapper<EmptyResponse> => {
    return await callBackend<SendPasswordRecoveryEmailRequest, EmptyResponse>(
        PREFIX + '/sendPasswordRecoveryEmail',
        ApiMethod.POST,
        {
            email,
        }
    )
}

export const resetPassword = async (
    contentCreatorID: string,
    token: string,
    password: string
): APIResponseWrapper<ContentAuthResponse> => {
    return await callBackend<ResetPasswordRequest, ContentAuthResponse>(
        PREFIX + '/resetPassword',
        ApiMethod.POST,
        {
            contentCreatorID,
            token,
            password,
        }
    )
}
